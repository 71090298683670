import { decorate, observable, action, computed, toJS } from "mobx"
import axios from "axios"
const GSON = require("gson")

class GooglePayStore {
  APP_MODE = process.env.APP_MODE
  constructor(RootStore) {
    this.RootStore = RootStore
  }

  get isSupported() {
    const item = this.getPaymentMethodData()
    const { currency } = this.RootStore.paymentStore.current_currency
    return item && item.supported_currencies.includes(currency)
  }

  getPaymentMethodData() {
    const { payment_methods } = this.RootStore.paymentStore.paymentOptions
    return payment_methods.find(({ name }) => name.toLowerCase() === "google_pay")
  }
  handleLog(type, data) {
    this.RootStore.logStore.handleConfigLog(type, data)
  }
  getOrderItems() {
    const selectedItems = this.RootStore.configStore.items || []
    const { rate, currency } = this.RootStore.paymentStore.current_currency
    const { order } = this.RootStore.configStore
    if (currency !== order.currency)
      return selectedItems.map(({ name, total_amount }) => ({
        label: name,
        price: (parseFloat(total_amount) * parseFloat(rate)).toFixed(2),
        type: "SUBTOTAL",
        status: "FINAL"
      }))
    return selectedItems.map(({ name, total_amount }) => ({
      label: name,
      type: "SUBTOTAL",
      status: "FINAL",
      price: parseFloat(total_amount).toFixed(2)
    }))
  }
  get getEnvironment() {
    const { publicKey } = this.RootStore.configStore.config.gateway || {}
    const isLive = publicKey && publicKey.includes("pk_live")
    return isLive ? "PRODUCTION" : "TEST"
  }
  get getLocale() {
    const { language } = this.RootStore.configStore || {}
    return language
  }

  handleErrorMsg(code) {
    const clientMsg = this.RootStore.localizationStore.getContent("google_pay_err_" + code, null)
    const callbackMsg = this.RootStore.localizationStore.getContent("google_pay_err_" + code, "en")
    this.RootStore.logStore.handleConfigLog(code, callbackMsg)
    this.RootStore.uIStore.showMsg("warning", clientMsg, this.RootStore.localizationStore.getContent("err_code_is", null) + code, 0)
    this.RootStore.uIStore.stopLoading()
  }

  handleOnError(error) {
    this.handleErrorMsg("0005")
    this.handleLog("ON_CANCEL", error)
    this.RootStore.uIStore.stopLoading()
  }

  async setupToken(token) {
    const app = this.RootStore.configStore.app
    const location = this.RootStore.configStore.location
    const request = {
      mode: this.APP_MODE,
      method: "POST",
      path: "/v2/token",
      location: location,
      headers: {
        session_token: this.RootStore.merchantStore.session,
        Application:
          "app_locale=" +
          app.app_locale +
          "|requirer=" +
          app.requirer +
          "|app_id=" +
          app.app_id +
          "|app_client_version=" +
          app.app_client_version +
          "|app_server_version=" +
          app.app_server_version +
          "|requirer_os=" +
          app.requirer_os +
          "|requirer_os_version=" +
          app.requirer_os_version +
          "|requirer_browser=" +
          app.requirer_browser +
          "|requirer_browser_version=" +
          app.requirer_browser_version
      },
      reqBody: {
        type: "googlepay",
        token_data: token,
        client_ip: this.RootStore.configStore.client_ip
      },
      token: this.RootStore.configStore.token,
      user_agent: app.user_agent ? app.user_agent : null
    }
    try {
      const { data } = await axios.post(this.RootStore.serverPath + "/api", request)
      const { id, errors } = data || {}
      if (id) {
        this.handleLog("TAP_TOKEN_1", id)
        return data
      }
      if (errors && errors.length) {
        this.handleErrorMsg(errors[0].code)
        throw new Error(errors[0].description)
      }
    } catch (error) {
      this.handleLog("TAP_TOKEN_3", error)
      this.handleErrorMsg("2122", error)
      throw new Error(error)
    }
  }

  async saveToken(token) {
    const res = await this.RootStore.paymentStore.setActivePaymentOption("GOOGLE_PAY", token)
    this.handleLog("GOOGLE_PAY_ACTIVE_PAYMENT", res)
  }

  async handleTransaction() {
    const sourceId = this.RootStore.paymentStore.source_id
    this.handleLog("SOURCE_ID", sourceId)
    await this.RootStore.apiStore.handleTransaction(sourceId, "GOOGLE_PAY", 0.0)
  }

  getPaymentRequest() {
    const method = this.getPaymentMethodData()
    const config = {
      apiVersion: toJS(method.api_version),
      apiVersionMinor: toJS(method.api_version_minor),
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: toJS(method.allowed_auth_methods),
            allowedCardNetworks: toJS(method.supported_card_brands)
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: method.gateway_name,
              gatewayMerchantId: this.RootStore.merchantStore.merchant.id
            }
          }
        }
      ],
      merchantInfo: {
        merchantId: method.gateway_merchant_id,
        merchantName: this.RootStore.merchantStore.merchant.name
      },
      transactionInfo: {
        countryCode: this.RootStore.merchantStore.country_code,
        currencyCode: this.RootStore.paymentStore.current_currency.currency,
        totalPrice: parseFloat(this.RootStore.paymentStore.current_currency.amount).toFixed(2),
        totalPriceLabel: "to " + this.RootStore.merchantStore.name,
        totalPriceStatus: "FINAL",
        checkoutOption: "COMPLETE_IMMEDIATE_PURCHASE",
        displayItems: this.getOrderItems()
      }
    }
    return { config, logo: method.image, name: { en: method.name, ar: method.name_ar } }
  }

  async onLoadPaymentData(paymentRequest) {
    this.handleLog("ON_PAYMENT_AUTHORIZED", toJS(this.getPaymentRequest()))
    this.RootStore.uIStore.startLoading("loader", this.RootStore.localizationStore.getContent("please_wait_msg", null), null)
    try {
      const { token } = paymentRequest.paymentMethodData.tokenizationData
      const jsonToken = JSON.parse(GSON.decode(token))
      const res = await this.setupToken(jsonToken)
      await this.saveToken(res.id)
      await this.handleTransaction()
    } catch (error) {
      this.handleErrorMsg("2120", error)
      this.handleLog("STATUS_FAILED", error)
    }
  }
}

decorate(GooglePayStore, {
  isLoading: observable,
  environment: observable,
  getPaymentRequest: action,
  onLoadPaymentData: action,
  getEnvironment: computed,
  isSupported: computed,
  getLocale: computed
})

export default GooglePayStore
