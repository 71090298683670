import React, { Component } from "react";
import { observer } from "mobx-react";
import Options from "./Options";
import Paths from "../../webpack/paths";
import TapButton from "./TapButton";
import Otp from "./Otp";
import ExtraFees from "./ExtraFees";
import TapSlider from "../lib/tapSlider/TapSlider";
import SupportedCurrencies from "./SupportedCurrencies";
import BusinessInfo from "./BusinessInfo";
import Items from "./Items/Items";
// import styled from "styled-components";
import FawryPay from "./FawryPay";

class Pay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: null,
    };
  }

  componentWillMount() {
    this.props.store.uIStore.goSellBtn({
      title: this.props.store.configStore.btn,
      color: "#2ACE00",
      active: false,
      loader: false,
    });
  }

  componentDidMount() {
    //show the main page on index no. 0 or fawry page on index 6
    if (this.props.store.uIStore.getPageIndex === 6) {
      this.props.store.uIStore.setPageIndex(6, "y");
    } else {
      this.props.store.uIStore.setPageIndex(0, "y");
    }
  }

  componentWillUnmount() {
    this.setState({
      payment: null,
    });
  }

  handlePayAction() {
    var store = this.props.store;

    if (store.uIStore.getIsActive === "FORM") {
      //get card token
      store.formStore.generateToken();
    } else {
      store.actionStore.onPayBtnClick();
    }
  }

  handlePayBtnClick() {
    var store = this.props.store;
    store.uIStore.goSellBtn({
      active: true,
      loader: true,
    });

    switch (store.uIStore.getPageIndex) {
      case 0:
        this.handlePayAction();
        break;
      case 1:
        store.actionStore.handleExtraFeesClick();
        break;
      case 2:
        store.actionStore.handleOTPClick();
        break;
      case 6:
        store.actionStore.handleClose();
        break;
    }
  }

  animationStatusHandler() {
    // //console.log("animationStatusHandler");
    // //console.log(this.props.store.uIStore.targetElement.current);

    if (this.props.store.uIStore.targetElement.current !== null) {
      this.props.store.uIStore.targetElement.current.textInput[0].focus();
    }
  }

  render() {
    let store = this.props.store;

    // var title = '', self = this, cards = {};

    // //console.log('options height', store.uIStore.mainHeight);

    //console.log('btn btn ', store.uIStore.btn);

    var symbol = store.localizationStore.getContent(
      "supported_currencies_symbol_" +
        store.configStore.order.currency.toLowerCase(),
      null
    );
    var order_labels = {
      items: store.localizationStore.getContent("items_list_title", null),
      desc: store.localizationStore.getContent("tranx_description_title", null),
    };

    var amount = store.configStore.order
      ? store.uIStore.formatNumber(
          store.configStore.order.amount.toFixed(
            store.configStore.order.decimal_digit
          )
        )
      : null;
    var items_total =
      store.configStore.language.toLowerCase() == "en"
        ? symbol + amount
        : amount + symbol;

    return (
      <React.Fragment>
        <TapSlider
          componentKey={store.uIStore.getPageIndex}
          axis={store.uIStore.pageDir}
          animationDuration={store.actionStore.sliderAnimationDuration}
          style={{
            height: store.uIStore.sliderHeight + 3 + "px",
            width: "100%",
          }}
          direction={store.uIStore.dir}
          animationStatus={this.animationStatusHandler.bind(this)}
        >
          <div
            key={0}
            id="gosell-gateway-main-container"
            style={{
              width: "100%",
              height: store.uIStore.mainHeight + 86 + "px",
              position: "relative",
            }}
          >
            <Options store={store} />
          </div>

          <div
            key={1}
            style={{ width: "100%", height: "100%", position: "relative" }}
          >
            <ExtraFees dir={store.uIStore.dir} store={store} />
          </div>

          <div
            key={2}
            style={{ width: "100%", height: "100%", position: "relative" }}
          >
            <Otp dir={store.uIStore.dir} store={store} />
          </div>

          <div
            key={3}
            style={{ width: "100%", height: "100%", position: "relative" }}
          >
            <SupportedCurrencies
              theme="inline"
              bgColor="white"
              height="100%"
              dir={store.uIStore.dir}
              store={store}
            />
          </div>

          <div
            key={4}
            style={{ width: "100%", height: "100%", position: "relative" }}
          >
            <BusinessInfo store={store} width="100%" height="100%" />
          </div>

          <div
            key={5}
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              overflow: "auto",
            }}
          >
            <Items
              store={store}
              dir={store.uIStore.dir}
              desc={store.configStore.tranx_description}
              items={store.configStore.items}
              labels={order_labels}
              total={items_total}
            />
          </div>

          <div
            key={6}
            style={{
              width: "100%",
              height: store.uIStore.mainHeight + 86 + "px",
              position: "relative",
            }}
          >
            {store.paymentStore.fawryPay != null ? (
              <FawryPay
                dir={store.uIStore.dir}
                store={store}
                statusMsg={store.localizationStore.getContent(
                  "fawry_pay_status_msg",
                  null
                )}
                type={store.paymentStore.fawryPay.type}
                smsMsg={store.localizationStore
                  .getContent("fawry_pay_sms_msg", null)
                  .replace(
                    "%@phone_number",
                    store.paymentStore.fawryPay.phoneNumber
                  )}
                emailMsg={store.localizationStore
                  .getContent("fawry_pay_email_msg", null)
                  .replace("%@email", store.paymentStore.fawryPay.email)}
                bothMsgs={store.localizationStore.getContent(
                  "fawry_pay_both_msg",
                  null
                )}
                orderCodeTitle={store.localizationStore.getContent(
                  "fawry_pay_order_code_title",
                  null
                )}
                orderCode={store.paymentStore.fawryPay.orderCode}
                expiry={store.localizationStore
                  .getContent("fawry_pay_expiry_msg", null)
                  .replace(
                    "%@expiry_date",
                    store.paymentStore.fawryPay.expiryDate
                  )
                  .replace(
                    "%@expiry_time",
                    store.paymentStore.fawryPay.expiryTime
                  )}
                locations={store.localizationStore
                  .getContent("fawry_pay_locations", null)
                  .replace(
                    "%@locations_url",
                    store.paymentStore.fawryPay.location
                  )}
              />
            ) : null}
          </div>
        </TapSlider>

        {store.uIStore.getPageIndex != 3 &&
        store.uIStore.getPageIndex != 4 &&
        store.uIStore.getPageIndex != 5 ? (
          <div
            style={{
              height: "86px",
              lineHeight: "86px",
              position: "relative",
              width: "100%",
            }}
          >
            <TapButton
              id="gosell-gateway-btn"
              dir={store.uIStore.dir}
              width="90%"
              height="44px"
              btnColor={store.uIStore.btn.color}
              active={store.uIStore.btn.active}
              animate={store.uIStore.btn.loader}
              withIcons={store.uIStore.getPageIndex != 6 ? true : false}
              handleClick={this.handlePayBtnClick.bind(this)}
              store={store}
            >
              {store.uIStore.btn.title}
            </TapButton>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default observer(Pay);
