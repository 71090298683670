import * as mobx from "mobx";
import axios from "axios";

class LogStore {
  constructor(RootStore) {
    this.RootStore = RootStore;
  }

  isTextHasCode(text) {
    const regex = /<script\b[^>]*>([\s\S]*?)<\/script>|<style\b[^>]*>([\s\S]*?)<\/style>|<\/?[a-z][\s\S]*?>/g;
    return regex.test(text??'');
  }

  async configLog(body) {
    var reqBody = Object.assign({}, body, {
      token: this.RootStore.configStore.token,
    });

    var res = null;
    await axios
      .post(this.RootStore.serverPath + "/config-log", reqBody)
      .then(async function (response) {
        res = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    return await res;
  }

  async serverLog(body) {
    var reqBody = Object.assign({}, body, {
      token: this.RootStore.configStore.token,
    });

    var res = null;
    await axios
      .post(this.RootStore.serverPath + "/server-log", reqBody)
      .then(async function (response) {
        res = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    return await res;
  }

  async handleApplePayLogs(code, msg) {
    var location = this.RootStore.configStore.location;
    var app = this.RootStore.configStore.app;

    await this.configLog({
      publicKey: this.RootStore.configStore.key
        ? this.RootStore.configStore.key
        : "undefined",
      code: code,
      message: this.isTextHasCode(msg) ? "" : msg,
      url: location
        ? location.protocol + "//" + location.host + location.path
        : "undefined",
      application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version,
      token: this.RootStore.merchantStore.session,
      internalToken: this.RootStore.configStore.token,
    });
  }

  async handleConfigLog(code, msg) {
    var location = this.RootStore.configStore.location;
    var app = this.RootStore.configStore.app;

    await this.configLog({
      publicKey: this.RootStore.configStore.key
        ? this.RootStore.configStore.key
        : "undefined",
      code: code,
      message: this.isTextHasCode(msg) ? "" : msg,
      url: location
        ? location.protocol + "//" + location.host + location.path
        : "undefined",
      application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version,
      token: this.RootStore.merchantStore.session,
      internalToken: this.RootStore.configStore.token,
    });

    if (!error || error == null) {
      var error = {
        errors: [
          {
            code: code,
            description: msg,
          },
        ],
      };

      await this.RootStore.configStore.callbackFunc(error);
    }
  }

  async handleServerLog(body) {
    var location = this.RootStore.configStore.location;
    var app = this.RootStore.configStore.app;

    var browser = this.RootStore.uIStore.browser;


    await this.serverLog({
      key: this.RootStore.configStore.key
        ? this.RootStore.configStore.key
        : "undefined",
      id: this.RootStore.configStore.merchantId
        ? this.RootStore.configStore.merchantId
        : null,
      api: body.api,
      mode: body.mode,
      method: body.method,
      httpStatus: body.httpStatus,
      tapStatus: body.tapStatus,
      message: body.message,
      request: body.request,
      response: body.response,
      userAgent: browser,
      url: location
        ? location.protocol + "//" + location.host + location.path
        : "undefined",
      application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version,
      token: this.RootStore.merchantStore.session,
      internalToken: this.RootStore.configStore.token,
    });
  }
}

mobx.decorate(LogStore, {});

export default LogStore;
