import * as mobx from "mobx"
import axios from "axios"

class ApplePayStore {
  APP_MODE = process.env.APP_MODE
  constructor(RootStore) {
    this.RootStore = RootStore
    this.reset()
  }

  reset() {
    this.merchantIdentifier = "merchant.tap.gosell"
    this.show = false
    this.isLoading = true

    this.cancelled = false
    this.status = false
  }

  async handleLog(code) {
    var self = this
    var supported_payments = mobx.toJS(self.RootStore.paymentStore.supported_payment_methods)

    if (supported_payments != null && supported_payments.length == 1 && supported_payments[0] == "APPLE_PAY") {
      self.show = false
      self.isLoading = true
      self.RootStore.uIStore.stopLoading()

      var client_msg = this.RootStore.localizationStore.getContent("apple_pay_err_" + code, null)
      var callback_msg = this.RootStore.localizationStore.getContent("apple_pay_err_" + code, "en")
      await this.RootStore.logStore.handleConfigLog(code, callback_msg)
      await this.RootStore.uIStore.showMsg("warning", client_msg, self.RootStore.localizationStore.getContent("err_code_is", null) + code)
    } else {
      self.show = false
      self.isLoading = false

      self.RootStore.uIStore.stopLoading()
    }
  }

  checkApplePay() {
    var self = this

    var applePaySession = window.ApplePaySession

    if (applePaySession) {
      if (window.location.protocol == "https:") {
        if (self.RootStore.uIStore.modalMode === "page") {
          ApplePaySession.canMakePaymentsWithActiveCard(this.merchantIdentifier).then(function (canMakePayments) {
            // if (casnMakePayments) {
            // Display Apple Pay Buttons here…
            self.show = true
            self.isLoading = false
            self.RootStore.uIStore.stopLoading()
            // } else {
            //   // Check for the existence of the openPaymentSetup method.
            //   self.openPaymentSetup();
            // }
          })
        } else {
          self.handleLog("0002")
        }
      } else {
        self.handleLog("0003")
      }
    } else {
      self.handleLog("0001")
    }
  }

  openPaymentSetup() {
    var self = this

    // Check for the existence of the openPaymentSetup method.
    if (ApplePaySession.openPaymentSetup) {
      // Display the Set up Apple Pay Button here…
      ApplePaySession.openPaymentSetup(self.merchantIdentifier)
        .then(function (success) {
          if (success) {
            // Open payment setup successful
            self.show = true
            self.isLoading = false
            self.RootStore.uIStore.stopLoading()
          } else {
            // Open payment setup failed
            self.handleLog("0004")
          }
        })
        .catch(function (e) {
          // Open payment setup error handling
          self.handleLog("0005")
        })
    }
  }

  requestPayment() {
    var self = this

    self.cancelled = false
    self.status = false

    var customer = this.RootStore.configStore.customer
    var supported_card_brands = this.RootStore.paymentStore.applePay && this.RootStore.paymentStore.applePay[0].supported_card_brands

    var request = {
      countryCode: this.RootStore.merchantStore.country_code,
      currencyCode: this.RootStore.paymentStore.current_currency.currency,
      supportedNetworks: supported_card_brands,
      merchantCapabilities: ["supports3DS"],
      billingContact: {
        phoneNumber: customer && customer.phone && customer.phone.country_code + customer.phone.number,
        emailAddress: customer && customer.email,
        givenName: customer && customer.first_name,
        familyName: customer && customer.last_name
      },
      total: {
        label: "to " + this.RootStore.merchantStore.name,
        amount: parseFloat(this.RootStore.paymentStore.current_currency.amount).toFixed(2)
      }
    }

    var session = new ApplePaySession(1, request)

    // Merchant Validation
    session.onvalidatemerchant = (event) => {
      self.RootStore.logStore.handleApplePayLogs("ON_VALIDATE_MERCHANT", mobx.toJS(event))

      if (event.isTrusted) {
        self.performValidation(event.validationURL).then((merchantSession) => {
          session.completeMerchantValidation(merchantSession)
        })
      }
    }

    session.onpaymentmethodselected = (event) => {
      self.RootStore.logStore.handleApplePayLogs("ON_PAYMENT_SELECTED", mobx.toJS(event))

      if (event.isTrusted) {
        var total = {
          label: "to " + this.RootStore.merchantStore.name,
          type: "final",
          amount: parseFloat(this.RootStore.paymentStore.current_currency.amount).toFixed(2)
        }

        var selectedItems = this.RootStore.configStore.items != null ? this.RootStore.configStore.items.slice() : []

        var lineItems = []

        if (selectedItems.length > 0) {
          if (this.RootStore.paymentStore.current_currency != this.RootStore.configStore.order.currency) {
            selectedItems.forEach((item, i) => {
              lineItems.push({
                type: "final",
                label: item.name,
                amount: (parseFloat(item.total_amount) * parseFloat(this.RootStore.paymentStore.current_currency.rate)).toFixed(2)
              })
            })
          } else {
            selectedItems.forEach((item, i) => {
              lineItems.push({
                type: "final",
                label: item.name,
                amount: parseFloat(item.total_amount).toFixed(2)
              })
            })
          }
        }

        session.completePaymentMethodSelection(total, lineItems)
      }
    }

    session.onpaymentauthorized = (event) => {
      self.RootStore.logStore.handleApplePayLogs("ON_PAYMENT_AUTHORIZED", mobx.toJS(event))

      if (!self.cancelled && event.isTrusted) {
        //create tap token
        self.sendPaymentToken(event.payment.token.paymentData).then((tokenObj) => {
          self.RootStore.logStore.handleApplePayLogs("TAP_TOKEN_CALLBACK", tokenObj)

          //if the process doesn't cancelled through onpaymentauthorized
          if (!self.cancelled && tokenObj && tokenObj.id) {
            self.saveTapToken(tokenObj, session)
          } else {
            // return failed status to apple pay
            self.status = ApplePaySession.STATUS_FAILURE

            //complete session
            session.completePayment(self.status)
          }
        })
      }
    }

    session.oncancel = (event) => {
      self.cancelled = true
      self.RootStore.logStore.handleApplePayLogs("ON_CANCEL", mobx.toJS(event))
      // window.location.reload();
    }

    session.begin()
  }

  saveTapToken(token, session) {
    var self = this
    // set APPLE_PAY as active payment
    self.RootStore.paymentStore.setActivePaymentOption("APPLE_PAY", token.id).then((res) => {
      // add the step to the logs
      self.RootStore.logStore.handleApplePayLogs("APPLE_PAY_ACTIVE_PAYMENT", res)

      // check if the process is cancelled or not then return the apple pay status
      if (!self.cancelled && res) {
        self.status = ApplePaySession.STATUS_SUCCESS
        self.RootStore.logStore.handleApplePayLogs("STATUS_SUCCESS", self.status)

        //complete session
        session.completePayment(self.status)

        self.createCharge()
      } else {
        self.status = ApplePaySession.STATUS_FAILURE
        self.RootStore.configStore.handleLog("2120", self.status)
        self.RootStore.logStore.handleApplePayLogs("STATUS_FAILED", self.status)

        //complete session
        session.completePayment(self.status)
      }
    })
  }

  createCharge() {
    var self = this
    var source_id = self.RootStore.paymentStore.source_id

    self.RootStore.logStore.handleApplePayLogs("SOURCE_ID", source_id)

    if (!self.cancelled && source_id != null) {
      // create the charge
      if (self.status == 0) {
        self.RootStore.uIStore.startLoading("loader", self.RootStore.localizationStore.getContent("please_wait_msg", null), null)
        self.RootStore.apiStore.handleTransaction(source_id, "APPLE_PAY", 0.0)
      } else {
        self.RootStore.logStore.handleApplePayLogs("STATUS", self.status)
      }
    } else {
      self.handleLog("2123")
    }
  }

  async performValidation(valURL) {
    var self = this

    var app = this.RootStore.configStore.app
    var location = this.RootStore.configStore.location

    var body = {
      validationUrl: valURL,
      merchantIdentifier: this.merchantIdentifier,
      merchantId: this.RootStore.merchantStore.merchant.id,
      merchantName: this.RootStore.merchantStore.name,
      origin: window.location != parent.location ? parent.location.hostname : window.location.hostname,
      application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version,
      location: location,
      session_token: this.RootStore.merchantStore.session,
      token: this.RootStore.configStore.token,
      user_agent: app.user_agent ? app.user_agent : null
    }

    var session = null
    await axios
      .post(self.RootStore.serverPath + "/session", body)
      .then(async (response) => {
        session = await response.data
        if (session && session.code == 200) {
          self.RootStore.logStore.handleApplePayLogs("APPLE_PAY_SESSION", "Apple Pay validation done successfully!")

          session = session.body
        } else {
          //Apple Pay validation issue
          self.RootStore.logStore.handleApplePayLogs("APPLE_PAY_SESSION", "Apple Pay validation issue!")

          await self.RootStore.configStore.handleLog("2121")
        }
      })
      .catch(async (error) => {
        await self.RootStore.logStore.handleApplePayLogs("APPLE_PAY_SESSION", error)
        await self.RootStore.configStore.handleLog("2121", error)
      })

    return await session
  }

  async sendPaymentToken(paymentToken) {
    var self = this
    //this is where you would pass the payment token to your third-party payment provider to use the token to charge the card. Only if your provider tells you the payment was successful should you return a resolve(true) here. Otherwise reject
    //defaulting to resolve(true) here, just to show what a successfully completed transaction flow looks like
    // var tokenObj = JSON.stringify(paymentToken);

    var app = this.RootStore.configStore.app
    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "POST",
      path: "/v2/token",
      location: location,
      headers: {
        session_token: this.RootStore.merchantStore.session,
        Application:
          "app_locale=" +
          app.app_locale +
          "|requirer=" +
          app.requirer +
          "|app_id=" +
          app.app_id +
          "|app_client_version=" +
          app.app_client_version +
          "|app_server_version=" +
          app.app_server_version +
          "|requirer_os=" +
          app.requirer_os +
          "|requirer_os_version=" +
          app.requirer_os_version +
          "|requirer_browser=" +
          app.requirer_browser +
          "|requirer_browser_version=" +
          app.requirer_browser_version
      },
      reqBody: {
        type: "applepay",
        token_data: paymentToken,
        client_ip: this.RootStore.configStore.client_ip
      },
      token: this.RootStore.configStore.token,
      user_agent: app.user_agent ? app.user_agent : null
    }

    var tokenObj = null

    if (!self.cancelled) {
      await axios
        .post(self.RootStore.serverPath + "/api", body)
        .then(async (response) => {
          tokenObj = await response.data

          if (tokenObj.id) {
            await self.RootStore.logStore.handleApplePayLogs("TAP_TOKEN_1", tokenObj.id)
          } else {
            //Tap token issue!
            await self.RootStore.logStore.handleApplePayLogs("TAP_TOKEN_2", tokenObj)
            await self.RootStore.configStore.handleLog("2122", tokenObj)
          }
        })
        .catch(async (error) => {
          await self.RootStore.logStore.handleApplePayLogs("TAP_TOKEN_3", error)
          await self.RootStore.configStore.handleLog("2122", error)
        })
    } else {
      await self.handleLog("2123")
    }

    return await tokenObj
  }

  get isSupported() {
    return this.RootStore.uIStore.modalMode === "page" && this.show && this.RootStore.paymentStore.isApplePayAvailable
  }
}

mobx.decorate(ApplePayStore, {
  merchantIdentifier: mobx.observable,
  show: mobx.observable,
  isLoading: mobx.observable,
  isSupported: mobx.computed
})

export default ApplePayStore
