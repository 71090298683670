import React from "react"
import GooglePayButton from "@google-pay/button-react"
import "../assets/css/googlePay.css"

function GooglePay({ store }) {
  if (!store.googlePayStore.isSupported) return null
  return (
    <GooglePayButton
      environment={store.googlePayStore.getEnvironment}
      buttonSizeMode='fill'
      buttonType='plain'
      buttonColor='black'
      buttonLocale={store.googlePayStore.getLocale}
      className={`button-container`}
      paymentRequest={store.googlePayStore.getPaymentRequest().config}
      onLoadPaymentData={async (data) => {
        await store.googlePayStore.onLoadPaymentData(data)
      }}
      onError={(err) => store.googlePayStore.handleOnError(err)}
    />
  )
}

export default GooglePay
