import React, { Component } from "react"
import { observer } from "mobx-react"
import Row from "./Row"
import Label from "./Label"
import Img from "./Img"
import Cards from "./Cards"
import SaveForm from "./SaveForm"
import Paths from "../../webpack/paths"
import Items from "./Items/Items"
import ApplePay from "./ApplePay"
import GooglePay from "./GooglePay"
import BenefitPay from "./BenefitPay"

class Options extends Component {
  constructor(props) {
    super(props)

    this.state = {
      payment: null,
      height: 0
    }
  }

  handleWebClick(payment) {
    this.setState({
      payment: payment
    })

    this.props.store.actionStore.onWebPaymentClick(payment)
  }

  componentDidMount() {
    this.props.store.uIStore.calcElementsHeight("gosell-gateway-payment-options")
  }

  componentDidUpdate(nextProps) {
    if (this.props.store.uIStore.mainHeight == 0 && document.getElementById("gosell-gateway-payment-options")) {
      this.props.store.uIStore.calcElementsHeight("gosell-gateway-payment-options")
    }
  }

  render() {
    var store = this.props.store

    const isBenefitPayExist = store.benefitPayStore.isSupported
    const isApplePayExist = store.applePayStore.isSupported
    const isGooglePayExist = store.googlePayStore.isSupported

    const styles = {
      row1: {
        rowContainer: {
          height: "65px",
          lineHeight: "65px",
          backgroundColor: "white",
          borderTop: "0.5px solid rgba(0, 0, 0, 0.17)",
          borderBottom: "0.5px solid rgba(0, 0, 0, 0.17)",
          "&:hover": {
            //    boxShadow: 'inset 0px 11px 0px -10px #2ACE00, inset 0px -11px 0px -10px #2ACE00'
          }
        },
        textStyle: { textAlign: "center" },
        iconStyle: { width: "65px", height: "65px", zIndex: "9999" }
      },
      row2: {
        rowContainer: {
          backgroundColor: "white",
          height: "46px",
          borderTop: "0.5px solid rgba(0, 0, 0, 0.17)",
          // borderBottom: "0.5px solid rgba(0, 0, 0, 0.17)",
          "&:hover": {
            //    boxShadow: 'inset 0px 11px 0px -10px #2ACE00, inset 0px -11px 0px -10px #2ACE00'
          }
        },
        iconStyle: { width: "65px", height: "48px" },
        textStyle: { width: "100%" },
        subtitle: {
          fontSize: "15px",
          lineHeight: store.uIStore.dir === "rtl" ? "40px" : "47px",
          margin: "0px 82px"
        }
      },
      row3: {
        rowContainer: {
          backgroundColor: "white",
          height: "46px",
          borderTop: "0.5px solid rgba(0, 0, 0, 0.17)",
          borderBottom: "0.5px solid rgba(0, 0, 0, 0.17)",
          "&:hover": {
            //    boxShadow: 'inset 0px 11px 0px -10px #2ACE00, inset 0px -11px 0px -10px #2ACE00'
          }
        },
        iconStyle: { width: "65px", height: "48px" },
        textStyle: { width: "100%" },
        subtitle: {
          fontSize: "15px",
          lineHeight: store.uIStore.dir === "rtl" ? "40px" : "47px",
          margin: "0px 82px"
        }
      },
      order_row: {
        rowContainer: {
          backgroundColor: "white",
          textAlign: "center",
          height: "30px",
          lineHeight: "30px",
          borderTop: "0.5px solid rgba(0, 0, 0, 0.17)",
          borderBottom: "0.5px solid rgba(0, 0, 0, 0.17)",
          "&:hover": {
            //    boxShadow: 'inset 0px 11px 0px -10px #2ACE00, inset 0px -11px 0px -10px #2ACE00'
          }
        },
        // 'iconStyle': {width: '100px', height: '30px'},
        textStyle: { width: "100%" },
        subtitle: {
          fontSize: "12px"
        }
      }
    }

    const WebPayments = store.paymentStore.getWebPaymentsByCurrency.map(
      (payment, index) =>
        // FIXME: remove this condition
        payment.source_id !== "src_benefit_pay" && (
          <div key={"div-" + index}>
            <Row
              key={payment.id}
              dir={store.uIStore.dir}
              style={store.paymentStore.getWebPaymentsByCurrency.length == index + 1 ? styles.row3 : styles.row2}
              rowIcon={<Img imgSrc={payment.image} imgWidth='35' imgHeight='35' style={{ padding: "6px 20px" }} />}
              rowTitle={{
                secondary: store.configStore.language == "ar" && payment.name_ar ? payment.name_ar : payment.name
                // store.localizationStore.getContent("payment_methods_" + payment.source_id.toLowerCase(), null, payment.name),
              }}
              onClick={this.handleWebClick.bind(this, payment)}
              addArrow={true}
            />

            {/* <Separator key={"separator-" + index} /> */}
          </div>
        )
    )

    if (store.paymentStore.customer_cards_by_currency) {
      const CardsList = store.paymentStore.customer_cards_by_currency.map((payment, index) => (
        <div key={"div-" + index}>
          <Img imgSrc={payment.image} imgWidth='30' />
        </div>
      ))
    }

    var symbol = store.configStore.order
      ? store.localizationStore.getContent("supported_currencies_symbol_" + store.configStore.order.currency.toLowerCase(), null)
      : null
    var amount = store.configStore.order
      ? store.uIStore.formatNumber(store.configStore.order.amount.toFixed(store.configStore.order.decimal_digit))
      : null
    var order_labels = {
      items: store.localizationStore.getContent("items_list_title", null),
      desc: store.localizationStore.getContent("tranx_description_title", null)
    }
    var items_total = store.configStore.language.toLowerCase() == "en" ? symbol + amount : amount + symbol

    var showRecent = store.paymentStore.customer_cards_by_currency && store.paymentStore.customer_cards_by_currency.length > 0
    var showOthers = store.paymentStore.isApplePayAvailable || WebPayments.length > 0 || store.paymentStore.getCardPaymentsByCurrency.length > 0
    return (
      <React.Fragment>
        <div id='gosell-gateway-order-details' ref={(el) => (this.orderDetails = el)} className='gosell-gateway-order-details'>
          <div style={{ height: "fit-content" }}>
            <Items
              dir={store.uIStore.dir}
              store={store}
              desc={store.configStore.tranx_description}
              items={store.configStore.items}
              labels={order_labels}
              total={items_total}
            />
          </div>
        </div>
        <div
          id='gosell-gateway-payment-options'
          style={{ height: this.props.store.uIStore.mainHeight }}
          ref={(el) => (this.paymentOptions = el)}
          className='gosell-gateway-payment-options'
        >
          {/* <Separator /> */}

          {store.paymentStore.supported_currencies && store.paymentStore.supported_currencies.length > 1 ? (
            <Row
              id='currencies'
              ref={(node) => (this.currencies = node)}
              dir={store.uIStore.dir}
              style={styles.row1}
              rowIcon={
                <Img
                  imgSrc={Paths.imgsPath + "bill.svg"}
                  imgWidth='18'
                  style={
                    store.uIStore.dir === "ltr"
                      ? {
                          borderRight: "0.5px solid rgba(0, 0, 0, 0.17)",
                          padding: "21px 23px"
                        }
                      : {
                          borderLeft: "0.5px solid rgba(0, 0, 0, 0.17)",
                          padding: "21px 23px"
                        }
                  }
                />
              }
              rowTitle={this.props.store.paymentStore.getCurrentValue}
              onClick={this.props.store.actionStore.currenciesHandleClick}
              onIconClick={this.props.store.actionStore.handleOrderDetailsClick}
              addArrow={true}
            />
          ) : (
            <Row
              id='currencies'
              ref={(node) => (this.currencies = node)}
              dir={store.uIStore.dir}
              style={styles.row1}
              rowTitle={this.props.store.paymentStore.getCurrentValue}
              onClick={this.props.store.actionStore.currenciesHandleClick}
              onIconClick={this.props.store.actionStore.handleOrderDetailsClick}
              addArrow={false}
            />
          )}
          {/* <Separator /> */}

          {showRecent ? <Cards ref='cards' store={store} cards={store.paymentStore.customer_cards_by_currency} dir={store.uIStore.dir} /> : null}

          {showRecent && showOthers ? (
            <Label title={store.localizationStore.getContent("payment_options_group_title_others", null)} dir={store.uIStore.dir} />
          ) : (
            <div style={{ paddingBottom: "20px" }}></div>
          )}
          {(isBenefitPayExist || isApplePayExist || isGooglePayExist) && (
            <div style={{ paddingBottom: "16px", paddingRight: "16px", paddingLeft: "16px", display: "flex", flexDirection: "column", gap: "7px" }}>
              {(isGooglePayExist || isApplePayExist) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px"
                  }}
                >
                  <ApplePay store={store} />
                  <GooglePay store={store} />
                </div>
              )}

              <BenefitPay store={store} />
            </div>
          )}
          {WebPayments.length > 0 ? (
            <div style={{ paddingBottom: "20px" }}>
              {/* <Separator /> */}
              {WebPayments}
            </div>
          ) : null}

          {store.paymentStore.getCardPaymentsByCurrency.length > 0 ? (
            <React.Fragment>
              {/* <Separator /> */}
              <SaveForm store={store} />
              {/* <Separator /> */}
            </React.Fragment>
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

export default observer(Options)
