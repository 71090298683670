import { decorate, observable } from "mobx"
import Paths from "../../webpack/paths"

class ConfigStore {
  constructor(RootStore) {
    this.RootStore = RootStore
    this.reset()
  }

  reset() {
    this.config = null
    this.location = null
    this.key = null
    this.gateway = {
      contactInfo: true,
      customerCards: true,
      language: "en",
      notifications: "standard",
      backgroundImg: null,
      saveCardOption: true,
      supportedCurrencies: "all",
      supportedPaymentMethods: "all"
    }

    this.contactInfo = true
    this.backgroundImg = null

    this.callback = null

    this.language = "en"
    require("../assets/css/fontsEn.css")

    this.labels = {}

    this.btn = null

    this.style = {
      base: {
        color: "#535353",
        lineHeight: "18px",
        fontFamily: this.language.toLowerCase() === "en" ? "Roboto-Light" : "Helvetica-Light",
        fontUrl: this.language.toLowerCase() === "en" ? Paths.cssPath + "fontsEn.css" : Paths.cssPath + "fontsAr.css",
        fontSmoothing: "antialiased",
        fontSize: "15px",
        "::placeholder": {
          color: "rgba(0, 0, 0, 0.26)",
          fontSize: this.language.toLowerCase() === "en" ? "15px" : "10px"
        }
      },
      invalid: {
        color: "red",
        iconColor: "#fa755a "
      }
    }

    this.transaction_mode = null
    this.tranx_description = null
    this.customer = null
    this.transaction = null

    this.order = null

    this.items = null
    this.shipping = null
    this.taxes = null

    this.redirect_url = null
    this.redirect_btn = null

    this.legalConfig = true

    this.notifications = "standard"

    this.view = ""

    this.token = null

    this.app = {
      app_locale: this.language,
      requirer: "web.checkout",
      app_id: "gosell.checkout.web",
      app_client_version: "1.4.0",
      app_server_version: "1.5.0",
      requirer_os: "unknown",
      requirer_os_version: "unknown",
      requirer_browser: "unknown",
      requirer_browser_version: "unknown"
    }

    this.client_ip = null
    this.customer_cur = null

    this.merchantId = null

    this.home = null
  }

  callbackFunc(data) {
    if (data && data != null) {
      var url = window.location != window.parent.location ? document.referrer : document.location.href

      parent.postMessage({ callback: data }, url)
    }
  }

  async setConfig(value, token, view) {
    if (
      window.location.hostname === "market.payments.tap.company" &&
      this.RootStore.uIStore.modalMode === "page" &&
      window.location != window.parent.location
    ) {
      // let url =
      //   window.location != window.parent.location
      //     ? document.referrer
      //     : document.location.href;

      let current = window.location.href
      parent.postMessage({ location: current }, "*")
    }

    var self = this
    this.config = value
    this.view = view
    this.token = token

    this.location = value.location ? value.location : null
    this.language = this.config.gateway && this.config.gateway.language ? this.config.gateway.language : "en"

    self.client_ip = await this.RootStore.apiStore.getIP().then(async (result) => {
      return await result
    })

    // console.log("self.client_ip", self.client_ip);

    self.merchantId = await (value.gateway && value.gateway.merchantId ? value.gateway.merchantId : null)

    // console.log("merchant ===> ", this.merchantId);

    this.app = value.app
      ? value.app
      : {
          app_locale: this.language,
          requirer: "web.checkout",
          app_id: "gosell.checkout.web",
          app_client_version: "1.4.0",
          app_server_version: "1.5.0",
          requirer_os: "unknown",
          requirer_os_version: "unknown",
          requirer_browser: "unknown",
          requirer_browser_version: "unknown"
        }

    console.log("app", this.app)
  }

  async handleLog(code, error) {
    var self = this
    var err_msg = this.RootStore.localizationStore.getContent("err_" + code, null)
    var client_msg = this.RootStore.localizationStore.getContent("gosell_configuration_msg", null)
    var connection_msg = this.RootStore.localizationStore.getContent("connection_issue_msg", null)

    console.log("err_msg", err_msg)
    console.log("client_msg", client_msg)

    var msg = error
      ? this.RootStore.localizationStore.getContent("err_" + code, "en") + " - " + error
      : this.RootStore.localizationStore.getContent("err_" + code, "en")

    await this.RootStore.logStore.handleConfigLog(code, msg)

    // await this.RootStore.apiStore.configLog({
    //   publicKey: this.key ? this.key : "undefined",
    //   code: code,
    //   message: msg,
    //   url: this.location
    //     ? this.location.protocol +
    //       "//" +
    //       this.location.host +
    //       this.location.path
    //     : "undefined",
    //   application:
    //     "app_locale=" +
    //     this.app.app_locale +
    //     "|requirer=" +
    //     this.app.requirer +
    //     "|app_id=" +
    //     this.app.app_id +
    //     "|app_client_version=" +
    //     this.app.app_client_version +
    //     "|app_server_version=" +
    //     this.app.app_server_version +
    //     "|requirer_os=" +
    //     this.app.requirer_os +
    //     "|requirer_os_version=" +
    //     this.app.requirer_os_version +
    //     "|requirer_browser=" +
    //     this.app.requirer_browser +
    //     "|requirer_browser_version=" +
    //     this.app.requirer_browser_version,
    //   token: this.RootStore.merchantStore.session,
    //   internalToken: this.token,
    // });

    // if (!error || error == null) {
    //   var error = {
    //     errors: [
    //       {
    //         code: code,
    //         description: this.RootStore.localizationStore.getContent(
    //           "err_" + code,
    //           "en"
    //         ),
    //       },
    //     ],
    //   };

    //   await this.RootStore.configStore.callbackFunc(error);
    // }

    if (this.config != null && typeof this.config.gateway != "undefined") {
      var mode = this.config.gateway.publicKey && this.config.gateway.publicKey.indexOf("pk_test") == 0
    }

    if (navigator.onLine) {
      await this.RootStore.uIStore.showMsg(
        "warning",
        mode ? err_msg : client_msg,
        self.RootStore.localizationStore.getContent("err_code_is", null) + code
      )
    } else {
      await this.RootStore.uIStore.showMsg(
        "error",
        mode ? err_msg : connection_msg,
        self.RootStore.localizationStore.getContent("err_code_is", null) + code
      )
    }
  }

  generateRedirectURL(redirect, mode, params) {
    var result = null
    var extra_params = params ? "&" + params : ""

    if (redirect && redirect.indexOf("#") != -1) {
      redirect = redirect.split("#")
      console.log("#2", redirect)

      if (redirect[0].indexOf("?") != -1) {
        //found
        result = redirect[0] + "&token=" + this.token + "&mode=" + mode + extra_params + "#" + redirect[1]
      } else {
        //nope
        result = redirect[0] + "?token=" + this.token + "&mode=" + mode + extra_params + "#" + redirect[1]
      }
    } else {
      if (redirect && redirect.indexOf("?") != -1) {
        //found
        result = redirect + "&token=" + this.token + "&mode=" + mode + extra_params
      } else {
        //nope
        result = redirect + "?token=" + this.token + "&mode=" + mode + extra_params
      }
    }
    return result
  }

  async configure() {
    if (this.config != null && typeof this.config.gateway != "undefined") {
      this.key = this.config.gateway ? this.config.gateway.publicKey : null
      this.callback = this.config.gateway && this.config.gateway.callback ? this.config.gateway.callback : null
      this.language = this.config.gateway && this.config.gateway.language ? this.config.gateway.language : "en"
      this.language.toLowerCase() === "en" ? require("../assets/css/fontsEn.css") : require("../assets/css/fontsAr.css")

      this.setGateway(this.config)

      // var forge = require("node-forge");
      //console.log('config', this.config);
      this.transaction_mode = this.config.transaction ? this.config.transaction.mode : null

      switch (this.transaction_mode) {
        case "charge":
          await this.setCustomer(this.config)
          await this.setTransaction(this.config.order, this.config.transaction.charge).then(async (result) => {
            //console.log('result', result);
            this.legalConfig = result
          })
          break
        case "authorize":
          await this.setCustomer(this.config)
          await this.setTransaction(this.config.order, this.config.transaction.authorize).then(async (result) => {
            //console.log('result', result);
            this.legalConfig = result
          })
          break
        case "save_card":
          await this.setCustomer(this.config)
          var redirect = this.location.protocol + "//" + this.location.host + this.location.path

          this.redirect_url = this.generateRedirectURL(redirect, this.RootStore.uIStore.modalMode, null)

          this.redirect_btn = redirect

          this.btn = this.RootStore.localizationStore.getContent("btn_save_title", null)
          // this.legalConfig = true;
          break
        case "token":
          var redirect = this.location.protocol + "//" + this.location.host + this.location.path

          this.redirect_btn = this.generateRedirectURL(redirect, this.RootStore.uIStore.modalMode, null)

          this.redirect_btn = redirect

          if (this.config.gateway.labels && this.config.gateway.labels.actionButton && this.config.gateway.labels.actionButton != null) {
            this.btn = this.config.gateway.labels.actionButton
          } else {
            this.btn = this.RootStore.localizationStore.getContent("btn_pay_title_generic", null)
          }
          // this.legalConfig = true;
          break
        default:
          if (typeof this.config.transaction.mode === "undefined") {
            this.handleLog("2116")

            this.legalConfig = false
          } else if (this.config.transaction.mode === null) {
            this.handleLog("2117")
            this.legalConfig = false
          }
      }
    } else {
      if (this.legalConfig) {
        this.handleLog("9999")
        this.legalConfig = false
      }
    }

    return await this.legalConfig
  }

  setOrder(value) {
    if (this.legalConfig) {
      if (this.transaction_mode != "token" && this.transaction_mode != "save_card") {
        if (value && value.amount && value.amount != null && value.currency && value.currency != null) {
          this.order = value
          this.items = value.items ? value.items.slice() : null
          this.shipping = value.shipping ? value.shipping : null
          this.taxes = value.taxes ? value.taxes.slice() : null

          this.legalConfig = true
        } else {
          this.handleLog("2113")
          this.legalConfig = false
        }
      }
    }

    return this.legalConfig
  }

  setCustomer(value) {
    if (this.legalConfig) {
      if (value.customer && value.customer != null) {
        // var forge = require("node-forge");

        var id = value.customer.id ? value.customer.id : null

        var firstName = value.customer.first_name ? value.customer.first_name : null
        var middleName = value.customer.middle_name ? value.customer.middle_name : null
        var lastName = value.customer.last_name ? value.customer.last_name : null
        var countryCode = value.customer.phone && value.customer.phone.country_code ? value.customer.phone.country_code : null
        var phone = value.customer.phone && value.customer.phone.number ? value.customer.phone.number : null
        var email = value.customer.email ? value.customer.email : null

        if (id != null || (firstName != null && ((countryCode != null && phone != null) || email != null))) {
          this.customer = {
            id: id,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            email: email,
            phone: {
              country_code: countryCode,
              number: phone
            }
          }
        } else {
          this.handleLog("2119")
          this.legalConfig = false
        }

        // this.customer = value.customer;
      } else if (this.transaction_mode === "save_card") {
        this.handleLog("2114")
        this.legalConfig = false
      }
    }
  }

  setGateway(value) {
    // console.log('from setGateway', this.legalConfig && (value.gateway && value.gateway != null));
    if (this.legalConfig) {
      // console.log("hey I am here!!", value.gateway);
      // console.log("hey I am here!!", value.gateway && value.gateway != null);

      if (value.gateway && value.gateway != null) {
        var URLSearchParams = require("@ungap/url-search-params/cjs")

        var urlParams = new URLSearchParams(window.location.search)
        // console.log("mode", this.RootStore.uIStore.modalMode);

        if (
          !urlParams.has("tap_id") &&
          this.RootStore.uIStore.modalMode === "popup" &&
          this.location &&
          this.location.protocol === "http:" &&
          value.gateway.publicKey &&
          value.gateway.publicKey.indexOf("pk_live") == 0
        ) {
          this.handleLog("2110")
          this.legalConfig = false
        } else if (
          this.RootStore.uIStore.modalMode === "page" &&
          window.location != window.parent.location &&
          window.location.hostname != "market.payments.tap.company"
        ) {
          this.handleLog("2124")
          this.legalConfig = false
        } else if (value.gateway.publicKey && value.gateway.publicKey.indexOf("sk_") == 0) {
          this.handleLog("2111")
          this.legalConfig = false
        } else if (typeof value.gateway.publicKey == "undefined" || value.gateway.publicKey == null) {
          this.handleLog("2112")
          this.legalConfig = false
        } else {
          // var forge = require("node-forge");

          // this.gateway = value.gateway;

          this.gateway = {
            publicKey: value.gateway.publicKey ? value.gateway.publicKey : null,
            merchantId: value.gateway.merchantId ? value.gateway.merchantId : null,
            contactInfo: typeof value.gateway.contactInfo != "undefined" ? value.gateway.contactInfo : true,
            customerCards: typeof value.gateway.customerCards != "undefined" ? value.gateway.customerCards : true,
            language: value.gateway.language ? value.gateway.language : "en",
            notifications: value.gateway.notifications ? value.gateway.notifications : "standard",
            callback: value.gateway.callback ? value.gateway.callback : null,
            backgroundImg: value.gateway.backgroundImg ? value.gateway.backgroundImg : this.RootStore.uIStore.background,
            saveCardOption: typeof value.gateway.saveCardOption != "undefined" ? value.gateway.saveCardOption : true,
            supportedCurrencies: value.gateway.supportedCurrencies ? value.gateway.supportedCurrencies : "all",
            supportedPaymentMethods: value.gateway.supportedPaymentMethods ? value.gateway.supportedPaymentMethods : "all",
            // content: value.gateway.content ? value.gateway.content : this.content,
            labels: value.gateway.labels ? value.gateway.labels : this.labels,
            style: value.gateway.style ? value.gateway.style : this.style
          }

          this.home = value.gateway && value.gateway.home ? value.gateway.home : this.home

          this.merchantId = value.gateway.merchantId ? value.gateway.merchantId : null
          // console.log("merchant 2 ===> ", this.merchantId);
          this.RootStore.uIStore.dir = this.language.toLowerCase() === "ar" ? "rtl" : "ltr"

          this.style.base.fontFamily = this.language.toLowerCase() === "en" ? "Roboto-Light" : "Helvetica-Light"
          this.style.base.fontUrl = this.language.toLowerCase() === "en" ? Paths.cssPath + "fontsEn.css" : Paths.cssPath + "fontsAr.css"

          this.contactInfo = !value.gateway.contactInfo ? value.gateway.contactInfo : this.contactInfo

          this.backgroundImg = value.gateway.backgroundImg ? value.gateway.backgroundImg : this.RootStore.uIStore.background

          this.language = value.gateway.language ? value.gateway.language : "en"

          this.RootStore.uIStore.dir = this.language.toLowerCase() === "ar" ? "rtl" : "ltr"

          if (value.gateway.supportedCurrencies) {
            if (typeof value.gateway.supportedCurrencies == "object") {
              var currencies = []
              value.gateway.supportedCurrencies.forEach(function (c) {
                currencies.push(c.toUpperCase())
              })

              this.gateway.supportedCurrencies = currencies
            } else {
              this.gateway.supportedCurrencies = value.gateway.supportedCurrencies.toLowerCase()
            }
          }

          if (value.gateway.supportedPaymentMethods) {
            if (typeof value.gateway.supportedPaymentMethods == "object") {
              var methods = []
              value.gateway.supportedPaymentMethods.forEach(function (c) {
                methods.push(c.toUpperCase())
              })

              this.gateway.supportedPaymentMethods = methods
            } else {
              this.gateway.supportedPaymentMethods = value.gateway.supportedPaymentMethods.toLowerCase()
            }
          }

          // this.gateway.saveCardOption = value.gateway.saveCardOption;
          // this.gateway.customerCards = value.gateway.customerCards;

          if (value.gateway && value.gateway.labels && value.gateway.labels.actionButton && value.gateway.labels.actionButton != null) {
            this.btn = value.gateway.labels.actionButton
          } else {
            if (this.transaction_mode === "save_card") {
              this.btn = this.RootStore.localizationStore.getContent("btn_save_title", null)
            } else {
              this.btn = this.RootStore.localizationStore.getContent("btn_pay_title_generic", null)
            }
          }

          // console.log('btn 0000', this.btn);
          // console.log('btn 00', this.RootStore.localizationStore.getContent('btn_pay_title_generic', null));

          if (value.gateway && value.gateway.labels) {
            this.labels = {
              cardNumber: value.gateway.labels.cardNumber
                ? value.gateway.labels.cardNumber
                : this.RootStore.localizationStore.getContent("card_input_card_number_placeholder", null),
              expirationDate: value.gateway.labels.expirationDate
                ? value.gateway.labels.expirationDate
                : this.RootStore.localizationStore.getContent("card_input_expiration_date_placeholder", null),
              cvv: value.gateway.labels.cvv
                ? value.gateway.labels.cvv
                : this.RootStore.localizationStore.getContent("card_input_cvv_placeholder", null),
              cardHolder: value.gateway.labels.cardHolder
                ? value.gateway.labels.cardHolder
                : this.RootStore.localizationStore.getContent("card_input_cardholder_name_placeholder", null),
              back: value.gateway.labels.back ? value.gateway.labels.back : this.RootStore.localizationStore.getContent("redirect_title", null)
            }
          } else {
            this.labels = {
              cardNumber: this.RootStore.localizationStore.getContent("card_input_card_number_placeholder", null),
              expirationDate: this.RootStore.localizationStore.getContent("card_input_expiration_date_placeholder", null),
              cvv: this.RootStore.localizationStore.getContent("card_input_cvv_placeholder", null),
              cardHolder: this.RootStore.localizationStore.getContent("card_input_cardholder_name_placeholder", null),
              back: this.RootStore.localizationStore.getContent("redirect_title", null)
            }
          }
          if (value.gateway.style && isEmpty(value.gateway.style)) {
            this.style = {
              base:
                value.gateway.style && value.gateway.style.base
                  ? value.gateway.style.base
                  : {
                      color: "#535353",
                      lineHeight: "18px",
                      fontFamily: this.language.toLowerCase() === "en" ? "Roboto-Light" : "Helvetica-Light",
                      fontUrl: this.language.toLowerCase() === "en" ? Paths.cssPath + "fontsEn.css" : Paths.cssPath + "fontsAr.css",
                      fontSmoothing: "antialiased",
                      fontSize: "15px",
                      "::placeholder": {
                        color: "rgba(0, 0, 0, 0.26)",
                        fontSize: this.language.toLowerCase() === "en" ? "15px" : "10px"
                      }
                    },
              invalid:
                value.gateway.style && value.gateway.style.invalid
                  ? value.gateway.style.invalid
                  : {
                      color: "red",
                      iconColor: "#fa755a "
                    }
            }
          }

          if (value.gateway.notifications && value.gateway.notifications !== "standard") {
            this.notifications = value.gateway.notifications
          }
        }
      } else {
        // this.handleLog("2109");
        this.legalConfig = false
      }
    }
  }

  async setTransaction(order, value) {
    var self = this

    if (this.legalConfig) {
      if (value) {
        if (Object.keys(value).length == 1 && value.id) {
          await this.RootStore.apiStore.getTransaction(value.id).then((result) => {
            // console.log("get charge transaction response", result);

            if (result.status === "INITIATED") {
              var redirect = result.data.redirect.url

              self.redirect_btn = redirect

              self.redirect_url = self.generateRedirectURL(redirect, self.RootStore.uIStore.modalMode, null)

              self.customer = result.data.customer

              self.transaction = {
                id: value.id,
                auto: result.data.auto ? result.data.auto : null,
                saveCard: result.data.save_card,
                threeDSecure: result.data.threeDSecure,
                description: result.data.description,
                statement_descriptor: result.data.statement_descriptor,
                reference: result.data.reference,
                destinations: result.data.destinations,
                metadata: result.data.metadata,
                receipt: result.data.receipt,
                redirect: result.data.redirect.url,
                post: result.data.post.url,
                hashstring: result.data.hashstring ? result.data.hashstring : null
              }

              // console.log("transaction sssssss", self.transaction);

              self.RootStore.paymentStore.transaction = self.transaction

              // console.log("order i s: ", this.order);

              self.tranx_description = self.transaction.description

              var orderDetails = {
                currency: result.data.currency,
                amount: result.data.amount
              }

              self.legalConfig = self.setOrder(orderDetails)
            } else {
              // this.handleLog("2115");

              this.RootStore.configStore.callbackFunc(result.data && result.data.errors)

              this.RootStore.uIStore.showMsg(
                "warning",
                this.RootStore.localizationStore.getContent("gosell_something_went_wrong", null),
                self.RootStore.localizationStore.getContent("res_id", null) + result.id
              )

              this.legalConfig = false
            }
          })
        } else {
          // var forge = require("node-forge");

          // self.transaction = value;

          self.transaction = {
            id: value.id,
            auto: value.auto ? value.auto : null,
            saveCard: value.saveCard,
            threeDSecure: value.threeDSecure,
            description: value.description,
            statement_descriptor: value.statement_descriptor,
            reference: value.reference,
            destinations: value.destinations,
            metadata: value.metadata,
            receipt: value.receipt,
            redirect: value.redirect,
            post: value.post,
            hashstring: value.hashstring ? value.hashstring : null
          }

          // console.log("transaction sssssss", self.transaction);

          self.RootStore.paymentStore.transaction = self.transaction

          self.tranx_description = self.transaction.description

          var redirect = value.redirect

          self.redirect_btn = redirect

          // console.log("#", redirect);
          // console.log("#1", redirect.indexOf("#"));

          self.redirect_url = self.generateRedirectURL(redirect, self.RootStore.uIStore.modalMode, null)

          // console.log("redirect_url", self.redirect_url);

          self.legalConfig = self.setOrder(order)
        }

        if (!self.gateway.saveCardOption) {
          self.transaction.saveCard = false
        }
      } else {
        this.handleLog("2115")
        this.legalConfig = false
      }
    }

    return await this.legalConfig
  }
}

function isEmpty(obj) {
  return Object.getOwnPropertyNames(obj).length === 0
}

decorate(ConfigStore, {
  gateway: observable,
  key: observable,
  language: observable,
  labels: observable,
  btn: observable,
  style: observable,
  transaction_mode: observable,
  transaction: observable,
  customer: observable,
  charge: observable,
  authorize: observable,
  saveCard: observable,
  token: observable,
  order: observable,
  items: observable,
  shipping: observable,
  taxes: observable,
  redirect_url: observable,
  legalConfig: observable,
  config: observable,
  notifications: observable,
  view: observable,
  contactInfo: observable,
  backgroundImg: observable,
  tranx_description: observable,
  callback: observable,
  location: observable,
  app: observable,
  client_ip: observable,
  // customer_cur: observable,
  merchantId: observable,
  home: observable
})

export default ConfigStore
