import {decorate, observable, computed} from 'mobx';
// import axios from 'axios';
// import myjson from 'https://goselljslib.b-cdn.net/local.json';
// import Paths from '../../webpack/paths';

class LocalizationStore {

  constructor(RootStore) {
    this.RootStore = RootStore;
    // this.strings = null;
    this.strings = require('./localization.json');

    // console.log('strings', this.strings);

    this.isLoading = false;
  }

  getContent(key, lang) {
    // console.log('from getContent', this.strings);
    if(this.strings!==null){
      const _defaultLang  = this.RootStore.configStore.language ? this.RootStore.configStore.language.toLowerCase() : 'en'
      const _lang = lang ? lang.toLowerCase() : _defaultLang;

      // console.log('key', key);
      if(key){
        if(this.strings[key]){
          const txt = this.strings[key][_lang];
          return txt;
        }
        else{
          return name;
        }

      } else {
        return ' ';
      }
    }
    else{
      return ' ';
    }
  }

  getContent(key, lang, name) {
    // console.log('from getContent', this.strings);
    if(this.strings!==null){
      const _defaultLang  = this.RootStore.configStore.language ? this.RootStore.configStore.language.toLowerCase() : 'en'
      const _lang = lang ? lang.toLowerCase() : _defaultLang;

      // console.log('key', key);
      if(key){
        if(this.strings[key]){
          const txt = this.strings[key][_lang];
          return txt;
        }
        else{
          return name;
        }

      } else {
        return ' ';
      }
    }
    else{
      return ' ';
    }
  }

  getContent(key, lang, name) {
      // console.log('from getContent', this.strings);
      if(this.strings!==null){
        const _defaultLang  = this.RootStore.configStore.language ? this.RootStore.configStore.language.toLowerCase() : 'en'
        const _lang = lang ? lang.toLowerCase() : _defaultLang;

        // console.log('key', key);
        if(key){
          if(this.strings[key]){
            const txt = this.strings[key][_lang];
            return txt;
          }
          else{
            return name;
          }
      }
      else{
        return ' ';
      }
    }
  }

}

decorate(LocalizationStore, {
  isLoading: observable,
  strings: observable
});

export default LocalizationStore;
