import React, { Component } from "react";
import styled from "styled-components";
import Item from "./Item";
// import Separator from "../Separator";

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
    };
  }

  // componentDidMount() {
    // if (this.props.items) {
    //   var itemsList =
    //     this.props.items != null &&
    //     this.props.items.map((item, index) => {
    //       // //console.log('item', item);
    //       // //console.log('item discount', item.discount);
    //       if (item) {
    //         // var forge = require("node-forge");

    //         return (
    //           <React.Fragment key={index}>
    //             <Item
    //               dir={this.props.dir}
    //               key={item.id}
    //               icon={null}
    //               title={item.name}
    //               store={this.props.store}
    //               amount_per_unit={item.amount_per_unit}
    //               discount={item.discount ? item.discount.value : null}
    //               qty={item.quantity}
    //               total={item.total_amount}
    //             />
    //             {/* <Separator /> */}
    //           </React.Fragment>
    //         );
    //       }
    //     });

    //   this.setState({
    //     items: itemsList,
    //   });
    // }
  // }

  render() {
    const Container = styled.div`
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    `;
    const Fieldset = styled.div`
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      background: #c9c9c9;
      color: white;
      padding: 0px 16px;
      text-align: ${this.props.dir === "rtl" ? "right" : "left"};
    `;

    const OrderDesc = styled.div`
      font-size: 14px;
      margin: 16px;
      text-align: ${this.props.dir === "rtl" ? "right" : "left"};
    `;
    let itemsList = null;
    let discount = 0;

    const Title = styled.div`
      font-size: 14px;
      color: #4A4A4A;
    `

    const SubTitle = styled.div`
      font-size: 12px;
      color: #8D9094;
    `

    const Badge = styled.div`
      width: 35px;
      height: 20px;
      line-height: 18px;
      background: #6B6F73;
      /* font-family: Roboto-Regular; */
      font-weight: 800;
      font-size: 13px;
      color: #F0F1F2;
      -webkit-letter-spacing: 0;
      -moz-letter-spacing: 0;
      -ms-letter-spacing: 0;
      letter-spacing: 0;
      text-align: center;
      border-radius: 40px;
      margin: ${this.props.dir === 'rtl' ? '0px 20px 0px 20px' : '0px 10px 0px 10px'};
      display: inline-block;
      float: ${this.props.dir === 'rtl' ? 'right' : ''};
      @media (max-width: 767px){
        margin: 0;
      }
    `

    const TotalAmount = styled.div`
      /* font-family: Roboto-Regular; */
      font-weight: 800;
      font-size: 14px;
      color: #4A4F54;
      letter-spacing: -0.11px;
      text-align: right;
      margin: 0px 10px 0px 10px;
      display: inline-block;
      direction: ${this.props.dir};
      `

    return (
      <Container>
        {this.props.desc ? (
          <React.Fragment>
            <Fieldset>
              {this.props.labels ? this.props.labels.desc : null}
            </Fieldset>

            <OrderDesc>{this.props.desc}</OrderDesc>
          </React.Fragment>
        ) : null}

        {this.props.items &&
        this.props.items != null &&
        this.props.items.length > 0 ? (
          <React.Fragment>
            <Fieldset>
              {this.props.labels ? this.props.labels.items : null}
            </Fieldset>
            {this.state.items}
            <table style={{ marginLeft: '16px', marginRight: '16px'}}>
            {this.props.items != null && this.props.items.map((item, index) => {
               return(<tbody key={ 'row-' + index }>
                  <tr style={{height: '40px'}}>
                  <td style={{ width:'40%'}}>
                    <Title>
                      {item.name}
                    </Title>

                    {item.amount_per_unit ?
                      <SubTitle>
                        {this.props.store.localizationStore.getContent('unit_price', null) + ": " + item.amount_per_unit}
                      </SubTitle>
                    : null}

                    {item.discount ?
                      <SubTitle>
                        {this.props.store.localizationStore.getContent('discount', null) + ": " +  item.discount.value}
                      </SubTitle>
                    : null}
                  </td>
                  <td  style={{ width: '10%'}}>
                    <Badge>
                      {item.quantity}
                    </Badge>
                  </td>
                  <td style={{ width: 'fit-content', textAlign: this.props.dir === 'ltr' ? 'right' : 'left' }}>
                  <TotalAmount>
                    {item.total_amount}
                  </TotalAmount>
                  </td>
                </tr>
              </tbody> );
        
            })}
              
            </table>
          
          </React.Fragment>
        ) : null}
      </Container>
    );
  }
}

export default Items;