import React, { Component } from "react"
import { decorate, observable, computed, action } from "mobx"
import { Modal, NotificationBar } from "../lib/modal"

class UIStore {
  constructor(RootStore) {
    this.RootStore = RootStore
    this.reset()
  }

  reset() {
    this.isModalEmpty = false
    this.openModal = false
    this.isLoading = true

    // this.btnLoader = false;
    this.msgLoader = false

    this.modalID = "gosell-lightbox-payment-gateway"
    // this.pay_btn = false;

    this.background = {
      url: "https://goSellJSLib.b-cdn.net/BG_IMG.jpg",
      opacity: "1"
    }

    //screen width --> switch between mobile / pc views
    this.width = window.innerWidth
    // this.isMobile = window.innerWidth <= 500 ? true : false;
    this.isMobile = window.innerWidth <= 823 ? true : false

    this.subPage = -1
    this.pageDir = "x"
    this.pageIndex = 0

    //the selected and active card in saved cards list
    this.isActive = null

    this.dir = "ltr"

    //shake the payment cards when the user press edit
    this.shake_cards = false
    this.delete_card = null

    this.notifications = "standard"

    this.load = true
    this.edit_customer_cards = null
    this.modalMode = "popup"

    this.mainHeight = 0
    this.sliderHeight = 0

    if (this.isMobile) {
      this.modalHeight = "90%"
      this.bodyHeight = "90%"
    } else {
      this.modalHeight = "fit-content"
      this.bodyHeight = "fit-content"
    }

    // this.modal_bg_img = "https://goSellJSLib.b-cdn.net/BG_IMG.jpg";
    // this.modal_bg_img = 'https://ak7.picdn.net/shutterstock/videos/10256567/thumb/1.jpg';
    // this.modal_bg_img = 'https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80';

    this.show_order_details = false

    this.errorHandler = {}
    this.msg = {}

    this.btn = {}
    this.otp = {
      updated: false,
      value: null
    }

    this.modal = {
      mode: "advanced",
      modalStyle: {
        modal: { width: "400px", height: "fit-content" },
        body: {
          backgroundColor: "#E9E9E9",
          height: "fit-content",
          minHeight: "227px"
        }
      },
      headerStyle: {
        header: {
          backgroundColor: "#F7F7F7",
          height: "auto",
          marginTop: "50px"
        },
        titleStyle: { cursor: "pointer", margin: "auto" },
        iconStyle: {
          background: "white",
          width: "75px",
          height: "75px",
          borderRadius: "100%",
          padding: "5px"
        }
      }
    }

    // , border: '3px solid #e9e9e9'

    this.closeNotification = this.closeNotification.bind(this)

    this.targetElement = React.createRef()
    this.browser = null
    this.deviceBrowser

    this.keyboard = false
  }

  calcElementsHeight(id) {
    const node = document.getElementById(id)

    if (node) {
      if (this.isMobile) {
        if (!this.keyboard) {
          // //

          this.setMainHeight(0)

          // var modalBodyHeight = document.getElementsByClassName("tap-payments-modal-body")[0].clientHeight - 86;

          var modalBodyHeight = this.bodyHeight - 86

          const allDivs = Array.from(node.querySelectorAll("#" + id + " > div"))

          var self = this
          var total = 10
          allDivs.forEach(function (element) {
            total += element.clientHeight
          })

          if (modalBodyHeight > total || (id === "gosell-gateway-form-container" && modalBodyHeight < total)) {
            this.setMainHeight(total)
            this.bodyHeight = total + 86
            this.modalHeight = this.bodyHeight + 65
          } else {
            this.setMainHeight(modalBodyHeight)
          }
        }
        // else {
        //   //
        // }
      } else {
        this.setMainHeight(0)

        const node = document.getElementById(id)

        const allDivs = Array.from(node.querySelectorAll("#" + id + " > div"))

        var total = 0

        allDivs.forEach(function (element) {
          total += element.clientHeight
        })

        this.setMainHeight(total)
      }

      this.calcModalHeight()
      this.setSliderHeight()
    }
  }

  setMainHeight(value) {
    this.mainHeight = value + 2

    if (this.isMobile) {
      var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName("body")[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight || documentElement.clientHeight || body.clientHeight

      this.modalHeight = height - 50

      this.bodyHeight = this.modalHeight - 65

      // //
      // //
      // //
    } else {
      // this.bodyHeight = this.mainHeight + 86;
      this.bodyHeight = this.mainHeight + 86

      // this.modalHeight = this.bodyHeight + 156;
      this.modalHeight = this.bodyHeight + 160
    }
  }

  calcModalHeight() {
    if (this.isMobile) {
      this.modal = {
        mode: "simple",
        modalStyle: {
          modal: { height: this.modalHeight },
          body: {
            backgroundColor: "#E9E9E9",
            height: this.bodyHeight,
            maxHeight: "90%"
          }
        },
        headerStyle: {
          header: { backgroundColor: "#F7F7F7", height: "65px" },
          titleStyle: { cursor: "pointer" },
          iconStyle: {
            justifyContent: "center",
            background: "white",
            borderRadius: "100%",
            width: "50px",
            height: "50px",
            margin: "6px"
          }
        }
      }

      // , border: '2px solid #e9e9e9'
    } else {
      this.modal = {
        mode: "advanced",
        modalStyle: {
          modal: { width: "400px", height: this.modalHeight },
          body: { backgroundColor: "#E9E9E9", height: this.bodyHeight }
        },
        headerStyle: {
          header: {
            backgroundColor: "#F7F7F7",
            height: "106px",
            marginTop: "50px"
          },
          titleStyle: { cursor: "pointer", margin: "auto 0" },
          iconStyle: {
            background: "white",
            width: "75px",
            height: "75px",
            borderRadius: "100%",
            padding: "5px"
          }
        }
      }
    }

    // , border: '3px solid #e9e9e9'
  }

  setSliderHeight() {
    var self = this
    // this.RootStore.formStore.blurCardForm();
    //
    //
    // setTimeout(function(){
    switch (this.getPageIndex) {
      case 0:
        self.sliderHeight = self.mainHeight
        break
      case 1:
        self.sliderHeight = self.mainHeight
        break
      case 2:
        self.sliderHeight = self.mainHeight
        break
      case 3:
        self.sliderHeight = self.bodyHeight
        break
      case 4:
        self.sliderHeight = self.bodyHeight
        break
      case 6:
        self.sliderHeight = self.mainHeight
        break
    }
    // }, 1000);

    // this.calcModalHeight();
  }

  formatNumber(num) {
    if (num) {
      if (typeof num == "string") {
        return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      } else {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      }
    } else {
      return num
    }
  }

  computed
  get deviceOS() {
    var useragent = navigator.userAgent
    if (useragent.match(/Android/i)) {
      return "phone"
    } else if (useragent.match(/webOS/i)) {
      return "phone"
    } else if (useragent.match(/iPhone/i)) {
      return "phone"
    } else if (useragent.match(/iPod/i)) {
      return "ipad"
    } else if (useragent.match(/iPad/i)) {
      return "ipad"
    } else if (useragent.match(/Windows Phone/i)) {
      return "phone"
    } else if (useragent.match(/SymbianOS/i)) {
      return "phone"
    } else if (useragent.match(/RIM/i) || useragent.match(/BB/i)) {
      //blackberry
      return "phone"
    } else {
      return "pc"
    }
  }

  computed
  get deviceBrowser() {
    var browser = null
    var isChromium = window.chrome
    var uA = navigator.userAgent,
      isIE =
        /msie\s|trident\/|edge\//i.test(uA) && !!(document.uniqueID || document.documentMode || window.ActiveXObject || window.MSInputMethodContext),
      checkVersion = (isIE && +/(edge\/|rv:|msie\s)([\d.]+)/i.exec(uA)[2]) || NaN
    var isOpera = navigator.userAgent.indexOf("OPR") > -1 || navigator.userAgent.indexOf("Opera") > -1
    if (!this.isMobile) {
      if (!!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g)) {
        browser = "IE"
      } else if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        browser = "FireFox"
      } else if (isChromium !== null && isOpera == true) {
        browser = "Opera"
      } else if (navigator.appVersion.indexOf("Edge") > -1) {
        browser = "Edge"
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        browser = "Chrome"
      } else if (navigator.userAgent.toLowerCase().indexOf("safari/") > -1) {
        browser = "Safari"
      }
      this.browser = browser
      return browser
    } else {
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      if (
        typeof InstallTrigger !== "undefined" ||
        navigator.userAgent.toLowerCase().indexOf("firefox") > -1 ||
        navigator.userAgent.toLowerCase().indexOf("fxios") > -1
      ) {
        browser = "FireFox"
      } else if (navigator.userAgent.toLowerCase().indexOf("edga/") >= 0 || navigator.userAgent.toLowerCase().indexOf("edgios/") >= 0) {
        browser = "Edge"
      } else if (
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0 ||
        navigator.userAgent.toLowerCase().indexOf("safari/") <= 0
      ) {
        browser = "Opera"
      } else if (
        (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) ||
        navigator.userAgent.indexOf("Chrome") != -1 ||
        navigator.userAgent.toLowerCase().indexOf("crios/") >= 0
      ) {
        browser = "Chrome"
      } else if (navigator.userAgent.toLowerCase().indexOf("safari/") > -1) {
        browser = "Safari"
      }
      this.browser = browser
      return browser
      // Blink engine detection
      // var isBlink = (isChrome || isOpera) && !!window.CSS;
    }
  }

  // computed
  // get dir(){
  //   return this.dir;
  // }

  // computed
  // get getOpenModal(){
  //   return this.openModal;
  // }

  setOpenModal(value) {
    this.openModal = value
  }

  // computed
  // get getLoadingStatus(){
  //   return this.isLoading;
  // }

  startLoading(loader_type, title, msg) {
    ////

    this.RootStore.uIStore.setMsg({
      type: loader_type,
      title: title,
      desc: msg,
      close: false
    })

    this.isLoading = true
    this.load = true
  }

  showResult(loader_type, title, msg) {
    this.RootStore.uIStore.setMsg({
      type: loader_type,
      title: title,
      desc: msg,
      handleClose: true
    })

    this.isLoading = true
    this.load = false
  }

  showMsg(loader_type, title, msg, delayTime = 1000) {
    var self = this
    self.startLoading("loader", this.RootStore.localizationStore.getContent("please_wait_msg", null), null)

    setTimeout(function () {
      self.showResult(loader_type, title, msg)
    }, delayTime)
  }

  redirectTo(url) {
    window.open(url, "_self")
  }

  stopLoading() {
    //
    //   "## localizationStore",
    //   this.RootStore.localizationStore.isLoading
    // );

    if (
      !this.RootStore.paymentStore.isLoading &&
      !this.RootStore.merchantStore.isLoading &&
      !this.RootStore.localizationStore.isLoading &&
      !this.RootStore.applePayStore.isLoading
    ) {
      this.isLoading = false
    }
  }

  setLoaderStatus(value) {
    this.isLoading = value
  }

  startBtnLoader() {
    this.btn.loader = true
  }

  stopBtnLoader() {
    setTimeout(() => {
      this.btn.loader = false
    }, 300)
  }

  computed
  get getMsgLoaderStatus() {
    return this.msgLoader
  }

  startMsgLoader() {
    this.msgLoader = true
  }

  stopMsgLoader() {
    this.msgLoader = false
  }

  setIsMobile(value) {
    this.isMobile = value
  }

  setSubPage(value) {
    this.subPage = value
  }

  computed
  get getSubPage() {
    return this.subPage
  }

  computed
  get getPageIndex() {
    return this.pageIndex
  }

  setPageIndex(value, dir) {
    var self = this

    if (dir === "x") {
      if (this.pageIndex > value) {
        this.dir === "ltr" ? (this.pageDir = "-x") : (this.pageDir = "x")
      } else if (this.pageIndex < value) {
        this.dir === "ltr" ? (this.pageDir = "x") : (this.pageDir = "-x")
      }
    } else if (dir === "y") {
      if (this.pageIndex > value) {
        this.pageDir = "-y"
      } else if (this.pageIndex < value) {
        this.pageDir = "y"
      }
    }

    switch (value) {
      case 0:
        self.goSellBtn({
          title: self.RootStore.configStore.btn,
          color: "#2ACE00",
          active: false,
          loader: false
        })
        break
      case 1:
        self.goSellBtn({
          title: self.RootStore.localizationStore.getContent("btn_confirm_title", null),
          color: "#007AFF",
          active: true,
          loader: false
        })
        break
      case 2:
        self.goSellBtn({
          title: self.RootStore.localizationStore.getContent("btn_confirm_title", null),
          color: "#007AFF",
          active: false,
          loader: false
        })
        break
      case 6:
        self.goSellBtn({
          title: self.RootStore.localizationStore.getContent("close_btn_title", null),
          color: "#007AFF",
          active: true,
          loader: false
        })
        break
    }

    this.pageIndex = value
    this.setSliderHeight()
  }

  computed
  get getIsActive() {
    return this.isActive
  }

  setIsActive(value) {
    if (value === "FORM" || value === "WEB") {
      this.delete_card = null
      this.edit_customer_cards = this.RootStore.localizationStore.getContent("common_edit", null)

      if (this.RootStore.configStore.transaction_mode !== "token" && this.RootStore.configStore.transaction_mode !== "token") {
        this.shakeCards(false)
      }
    } else {
      this.detete_card = false
    }

    this.isActive = value
  }

  shakeCards(value) {
    var skake = this.shake_cards
    this.goSellBtn({
      title: this.RootStore.configStore.btn,
      color: "#2ACE00",
      active: false,
      loader: false
    })

    if (!value) {
      this.shake_cards = false
      this.edit_customer_cards = this.RootStore.localizationStore.getContent("common_edit", null)
    } else {
      this.shake_cards = true
      // this.errorHandler = {};
      this.edit_customer_cards = this.RootStore.localizationStore.getContent("common_cancel", null)
      if (this.getSubPage === 1 || this.getSubPage === 0) {
        this.setSubPage(-1)
      }
    }

    this.RootStore.paymentStore.selected_card = null
  }

  goSellBtn(value) {
    if (!value.active) {
      ////
      this.btn.active = false
      // this.RootStore.paymentStore.active_payment_option_total_amount = 0;
    }

    this.btn = {
      title: value.title ? value.title : this.btn.title,
      color: value.color ? value.color : this.btn.color,
      active: value.active ? value.active : this.btn.active,
      loader: value.loader ? value.loader : this.btn.loader
    }

    // ////
  }

  goSellOtp(value) {
    this.otp = {
      updated: value.updated ? value.updated : this.otp.updated,
      value: value.value ? value.value : this.otp.value
    }
  }

  closeNotification() {
    var self = this

    if (self.delete_card === null && self.errorHandler.options == null) {
      this.errorHandler.visable = false
      setTimeout(function () {
        self.errorHandler = {}
      }, 500)
    }
  }

  computed
  get generateCustomNotification() {
    var self = this
    if (this.RootStore.configStore.notifications !== "standard" && !this.getErrorHandler.options) {
      ////

      var el = document.getElementById(this.RootStore.configStore.notifications)
      ////

      if (this.getErrorHandler.msg && el != null) {
        ////
        el.innerHTML = this.getErrorHandler.msg
      }
      setTimeout(function () {
        self.closeNotification()
      }, 5000)
    } else if (this.RootStore.configStore.notifications === "standard" || this.getErrorHandler.options) {
      return (
        <NotificationBar
          mode={this.getErrorHandler.type}
          dir={this.dir}
          close={this.closeNotification}
          show={this.getErrorHandler.visable}
          options={this.getErrorHandler.options}
        >
          {this.getErrorHandler.msg}
        </NotificationBar>
      )
    }
  }

  computed
  get getErrorHandler() {
    return this.errorHandler
  }

  setErrorHandler(value) {
    var self = this
    this.errorHandler = value

    if (value.visable) {
      window.scroll(0, 0)
    }

    setTimeout(function () {
      self.closeNotification()
    }, 5000)
  }

  warningHandler() {
    this.setErrorHandler({
      visable: true,
      code: "error",
      msg: this.RootStore.localizationStore.getContent("gosell_payment_process_warning_msg", null),
      type: "warning"
    })
  }

  computed
  get getMsg() {
    return this.msg
  }

  setMsg(value) {
    this.msg = value
  }
  setModalEmpty(value) {
    this.isModalEmpty = value
  }

  setFawryView() {
    this.setPageIndex(6, "y")
    this.calcElementsHeight("gosell-gateway-fawry-pay")
    this.stopLoading()
    this.setOpenModal(true)
  }
}

decorate(UIStore, {
  isModalEmpty: observable,
  setLoaderStatus: action,
  openModal: observable,
  isLoading: observable,
  load: observable,
  activePage: observable,
  pageIndex: observable,
  subPage: observable,
  isActive: observable,
  expandCurrencies: observable,
  expandBusinessInfo: observable,
  dir: observable,
  isMobile: observable,
  width: observable,
  btnLoader: observable,
  shake_cards: observable,
  delete_card: observable,
  slide_up: observable,
  msgLoader: observable,
  notifications: observable,
  confirm: observable,
  background: observable,
  edit_customer_cards: observable,
  errorHandler: observable,
  msg: observable,
  modalMode: observable,
  // modal_bg_img: observable,
  pageDir: observable,
  show_order_details: observable,
  mainHeight: observable,
  animationStatus: observable,
  btn: observable,
  otp: observable,
  modal: observable,
  modalHeight: observable,
  bodyHeight: observable,
  sliderHeight: observable,
  browser: observable,
  modalID: observable,
  keyboard: observable
})

export default UIStore
