import React, { Component } from "react"
import { observer } from "mobx-react"
import { Modal, Header } from "../lib/modal"
import Paths from "../../webpack/paths"
import "../assets/css/style.css"
import MainView from "./MainView"
import Details from "./Details"
import TapLoader from "./TapLoader"
import RootStore from "../store/RootStore.js"
import BackBtn from "./BackBtn"
import Footer from "./Footer"

class GoSell extends Component {
  //open Tap gateway as a light box by JS library
  static openLightBox() {
    RootStore.uIStore.modalMode = "popup"
    RootStore.uIStore.modalID = "gosell-lightbox-payment-gateway"

    GoSell.handleView()

    var params = RootStore.actionStore.getParams()

    RootStore.apiStore.decodeToken(params).then((config) => {
      if (config) {
        RootStore.uIStore.startLoading("loader", RootStore.localizationStore.getContent("please_wait_msg", null), null)

        RootStore.configStore.setConfig(config, params.token, "GOSELL").then(() => {
          RootStore.configStore.configure().then((result) => {
            if (result) {
              if (!GoSell.showTranxResult()) {
                RootStore.apiStore.init(RootStore.configStore.key)
              }
            }
          })
        })
      } else {
        RootStore.configStore.handleLog("9999")
      }
    })
  }

  //function will be used on tap server to generate the UI of the payment gateway
  static generateTapGateway() {
    RootStore.uIStore.modalMode = "page"
    RootStore.uIStore.modalID = "gosell-payment-gateway-page"

    GoSell.handleView()

    var params = RootStore.actionStore.getParams()

    RootStore.apiStore.decodeToken(params).then((config) => {
      if (config) {
        RootStore.uIStore.startLoading("loader", RootStore.localizationStore.getContent("please_wait_msg", null), null)

        RootStore.configStore.setConfig(config, params.token, "GOSELL").then(() => {
          RootStore.configStore.configure().then((result) => {
            if (result) {
              if (!GoSell.showTranxResult()) {
                RootStore.apiStore.init(RootStore.configStore.key)
              }
            }
          })
        })
      } else {
        RootStore.configStore.handleLog("9999")
      }
    })
  }

  //redirect to Tap gateway from JS library without calling charge / authrorize API from merchant side
  static openPaymentPage() {
    GoSell.handleView()
    RootStore.configStore.configure().then((result) => {
      RootStore.uIStore.startLoading("loader", RootStore.localizationStore.getContent("please_wait_msg", null), null)
      RootStore.apiStore
        .createTransaction()
        .then((result) => {
          if (result.status == 200) {
            window.open(result.data.transaction.url, "_self")
          }
        })
        .catch((error) => {})
    })
  }

  static handleView() {
    RootStore.uIStore.setErrorHandler({})
    RootStore.uIStore.startLoading("loader", null, null)
    // RootStore.configStore.configure();
    RootStore.uIStore.setOpenModal(true)
    // RootStore.configStore.setGlobalStyle();
  }

  static showTranxResult() {
    var URLSearchParams = require("@ungap/url-search-params/cjs")

    var urlParams = new URLSearchParams(window.location.search)

    var tap_id = null

    if (urlParams.has("tap_id") && urlParams.has("mode") && urlParams.has("token")) {
      var params = RootStore.actionStore.getParams()

      RootStore.apiStore.decodeToken(params).then((result) => {
        if (result) {
          RootStore.configStore.setConfig(result, params.token, "GOSELL")
          GoSell.handleView()
          tap_id = urlParams.get("tap_id")
          RootStore.apiStore.getTransactionResult(tap_id)
        } else {
          RootStore.configStore.handleLog("9999")
        }
      })

      return true
    } else {
      return false
    }
  }

  componentWillMount() {
    this.handleWindowSizeChange()
  }

  componentWillReceiveProps(nextProps) {
    // //
    this.handleWindowSizeChange()
  }

  componentDidMount() {
    RootStore.uIStore.calcModalHeight()

    // window.addEventListener('resize', RootStore.uIStore.calcModalHeight());
    window.addEventListener("resize", this.handleWindowSizeChange)
    window.addEventListener("resize", this.updateDimensions)
  }

  handleClick() {
    RootStore.actionStore.handleBusinessInfoClick()
  }

  updateDimensions() {
    RootStore.uIStore.calcElementsHeight("gosell-gateway-payment-options")
  }

  handleWindowSizeChange() {
    var device = RootStore.uIStore.deviceOS

    if (window.innerWidth <= 823 && device === "phone") {
      RootStore.uIStore.setIsMobile(true)
      RootStore.uIStore.setSubPage(-1)
    } else {
      RootStore.uIStore.setIsMobile(false)
      RootStore.uIStore.setPageIndex(0, "x")
    }

    if (window.innerHeight < window.innerWidth && device === "phone") {
      // this.updateDimensions();
      RootStore.uIStore.setErrorHandler({
        visable: true,
        code: "error",
        msg: RootStore.localizationStore.getContent("device_rotation_msg", null),
        type: "warning"
      })
    } else {
      // this.updateDimensions();
      RootStore.uIStore.errorHandler.visable = false
      RootStore.uIStore.setErrorHandler({})
    }
  }

  close() {
    RootStore.uIStore.getErrorHandler.visable = false
  }

  closeModal() {
    var url = window.location != window.parent.location ? document.referrer : document.location.href

    if (RootStore.uIStore.modalMode === "page" && window.location == window.parent.location) {
      window.open(RootStore.configStore.redirect_btn, "_self")
    } else {
      var close = "close"

      RootStore.configStore.app.app_client_version == "1.4.0" ? (close = "close") : (close = { close: RootStore.configStore.redirect_btn })
      parent.postMessage(close, url)
    }
  }

  autoClose() {
    var url = window.location != window.parent.location ? document.referrer : document.location.href

    if (RootStore.uIStore.modalMode === "page" && window.location == window.parent.location) {
      setTimeout(() => {
        window.open(RootStore.configStore.redirect_btn, "_self")
      }, 10000)
    } else {
      setTimeout(() => {
        var close = "close"

        RootStore.configStore.app.app_client_version == "1.4.0" ? (close = "close") : (close = { close: RootStore.configStore.redirect_btn })
        parent.postMessage(close, url)
      }, 10000)
    }
  }

  render() {
    let iconVisibility = "none"

    if (
      RootStore.uIStore.getPageIndex != 6 &&
      RootStore.uIStore.modalMode === "popup" &&
      RootStore.uIStore.delete_card == null &&
      !RootStore.uIStore.btn.loader
    ) {
      iconVisibility = "closeIn"
    } else if (
      RootStore.uIStore.getPageIndex != 6 &&
      RootStore.uIStore.modalMode === "page" &&
      RootStore.configStore.home != null &&
      RootStore.uIStore.delete_card == null &&
      !RootStore.uIStore.btn.loader
    ) {
      iconVisibility = "closeIn"
    }

    return (
      <React.Fragment>
        <Modal
          id={RootStore.uIStore.modalID}
          open={RootStore.uIStore.openModal}
          blur={true}
          isLoading={RootStore.uIStore.isLoading}
          isModalEmpty={RootStore.uIStore.isModalEmpty}
          loader={
            <TapLoader
              dir={RootStore.configStore.language.toLowerCase() === "ar" ? "rtl" : "ltr"}
              type={RootStore.uIStore.msg.type}
              status={RootStore.uIStore.load}
              color={RootStore.uIStore.modalMode === "popup" ? "white" : "black"}
              duration={5}
              title={RootStore.uIStore.msg.title}
              desc={RootStore.uIStore.msg.desc}
              closeTitle={
                RootStore.uIStore.modalMode === "popup"
                  ? RootStore.localizationStore.getContent("close_btn_title", null)
                  : RootStore.localizationStore.getContent("close_btn_title_page_mode", null)
              }
              close={RootStore.uIStore.msg.handleClose}
              autoClose={this.autoClose.bind(this)}
              handleClose={this.closeModal.bind(this)}
            />
          }
          animate={true}
          style={RootStore.uIStore.modal.modalStyle}
          mode={RootStore.uIStore.modalMode}
          pageBg={RootStore.uIStore.modalMode === "page" ? "linear-gradient(rgb(152, 171, 175) 0%, rgb(217, 214, 204) 100%)" : null}
          pageBgStyle={
            RootStore.configStore.backgroundImg && RootStore.configStore.backgroundImg.opacity
              ? { opacity: RootStore.configStore.backgroundImg.opacity }
              : { opacity: "1" }
          }
          notification={RootStore.uIStore.generateCustomNotification}
          header={
            <Header
              dir={RootStore.uIStore.dir}
              mode={RootStore.uIStore.modal.mode}
              modalIcon={<BackBtn store={RootStore} />}
              modalTitle={<Details store={RootStore} />}
              close={iconVisibility}
              closeIcon={Paths.imgsPath + (RootStore.uIStore.modalMode === "popup" ? "close.svg" : "home.svg")}
              onClose={(e) => {
                RootStore.actionStore.handleClose()
                // RootStore.uIStore.setOpenModal(false)
                // RootStore.actionStore.handleModalClose()
              }}
              style={RootStore.uIStore.modal.headerStyle}
              separator={false}
            ></Header>
          }
          footer={
            RootStore.uIStore.modalMode === "page" && RootStore.uIStore.getPageIndex != 6 ? (
              <Footer
                text={RootStore.configStore.labels && RootStore.configStore.labels.back}
                onClick={(e) => {
                  RootStore.actionStore.handleClose()
                }}
              />
            ) : null
          }
        >
          {!RootStore.uIStore.isLoading ? <MainView store={RootStore} /> : null}
        </Modal>
      </React.Fragment>
    )
  }
}

export default observer(GoSell)
