import { decorate, observable } from "mobx"
import axios from "axios"

const API_MODE = "production" //"DEV"
class ApiStore {
  APP_MODE = process.env.APP_MODE
  constructor(RootStore) {
    this.RootStore = RootStore
    this.getMerchantDetailsFlag = false
    this.setPaymentOptionsFlag = false
  }

  updateLoader() {
    if (this.getMerchantDetailsFlag && this.setPaymentOptionsFlag) {
      this.RootStore.uIStore.stopLoading()
      this.getMerchantDetailsFlag = false
      this.setPaymentOptionsFlag = false
    }
  }

  async decodeToken(body) {
    var self = this

    var res = null

    await axios
      .post(this.RootStore.serverPath + "/decode", { token: body.token })
      .then(async function (response) {
        res = response.data
        //
        //
        if (response.status == 200) {
          if (res.errors) {
            self.sendResponse(res)
            self.RootStore.configStore.legalConfig = false
          } else {
            if (body.tap_id == null && res.used === 1) {
              // var parseData = JSON.parse(res.config);
              var parseData = res.config
              var redirect = null

              //
              //

              switch (parseData.transaction.mode) {
                case "charge":
                  redirect = parseData.transaction.charge ? parseData.transaction.charge.redirect : null
                  //
                  break
                case "authorize":
                  redirect = parseData.transaction.authorize ? parseData.transaction.authorize.redirect : null
                  //
                  break
              }

              self.RootStore.configStore.redirect_btn = redirect

              self.RootStore.configStore.backgroundImg = res.config.gateway.backgroundImg
                ? res.config.gateway.backgroundImg
                : self.RootStore.uIStore.background

              self.RootStore.uIStore.showMsg(
                "warning",
                self.RootStore.localizationStore.getContent("session_error_msg", null),
                self.RootStore.localizationStore.getContent("err_code_is", null) + "2118"
              )

              // self.sendResponse(res);
              self.RootStore.configStore.legalConfig = false
            } else {
              // res = JSON.parse(res.config);
              res = res.config
            }
          }
          //
        } else {
          self.sendResponse(res)
          self.RootStore.configStore.legalConfig = false
        }
      })
      .catch(function (error) {})

    return await res
  }

  async updateToken(token) {
    var self = this

    var res = null

    await axios
      .post(this.RootStore.serverPath + "/update", { token: token })
      .then(async function (response) {
        res = response.data
        //
        //
        if (response.status == 200) {
          if (res.errors) {
            self.sendResponse(res)
            self.RootStore.configStore.legalConfig = false
          }
        } else {
          self.sendResponse(res)
          self.RootStore.configStore.legalConfig = false
        }
      })
      .catch(function (error) {})

    return await res
  }

  async auth(publicKey) {
    var self = this

    var app = this.RootStore.configStore.app
    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      location: location,
      headers: {
        authorization: "Bearer " + publicKey,
        Application:
          "app_locale=" +
          app.app_locale +
          "|requirer=" +
          app.requirer +
          "|app_id=" +
          app.app_id +
          "|app_client_version=" +
          app.app_client_version +
          "|app_server_version=" +
          app.app_server_version +
          "|requirer_os=" +
          app.requirer_os +
          "|requirer_os_version=" +
          app.requirer_os_version +
          "|requirer_browser=" +
          app.requirer_browser +
          "|requirer_browser_version=" +
          app.requirer_browser_version
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null,
      data = null
    await axios
      .post(this.RootStore.serverPath + "/auth", body)
      .then(async function (response) {
        res = response.data

        if (res.status === "success") {
          data = res.data

          self.mode = data.live_mode
          self.RootStore.merchantStore.merchant = {
            id: data.merchant.id,
            name: data.merchant.name
          }
          // self.RootStore.uIStore.modal_bg_img =
          //   self.RootStore.configStore.backgroundImg &&
          //   self.RootStore.configStore.backgroundImg.url != null
          //     ? self.RootStore.configStore.backgroundImg.url
          //     : data.merchant.background.url;

          self.RootStore.merchantStore.pk = self.RootStore.configStore.gateway.publicKey
          self.RootStore.merchantStore.session = await data.session_token

          //

          self.RootStore.paymentStore.status_display_duration = data.sdk_settings.status_display_duration
          self.RootStore.paymentStore.otp_resend_interval = data.sdk_settings.otp_resend_interval
          self.RootStore.paymentStore.otp_resend_attempts = data.sdk_settings.otp_resend_attempts

          self.RootStore.paymentStore.card_wallet = data.permission.card_wallet
          // self.RootStore.paymentStore.setThreeDSecure(
          //   data.permission.threeDSecure
          // );
        } else if (response.data.error || response.data.errors) {
          self.sendResponse(response.data)
        }
      })
      .catch(function (error) {
        self.sendResponse(error)
      })

    return await res
  }

  async init(publicKey) {
    var self = this
    self.setPaymentOptionsFlag = false
    self.getMerchantDetailsFlag = true

    var mode = null
    switch (this.RootStore.configStore.transaction_mode) {
      case "charge":
        mode = "PURCHASE"
        break
      case "authorize":
        mode = "AUTHORIZE_CAPTURE"
        break
      default:
        mode = null
        break
    }

    var customer = null

    if (
      this.RootStore.configStore.gateway.customerCards &&
      this.RootStore.configStore.customer
      // && this.RootStore.configStore.customer.id
    ) {
      customer = this.RootStore.configStore.customer
    }

    var app = this.RootStore.configStore.app

    var headers = {
      authorization: "Bearer " + publicKey,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "POST",
      headers: headers,
      location: location,
      reqBody: {
        // payment_type: "WEB",
        public_key: publicKey,
        transaction_mode: mode,
        customer: customer && customer.id,
        buyer: {
          email: customer && customer.email,
          phone: {
            code: customer && customer.phone && customer.phone.code,
            number: customer && customer.phone && customer.phone.numbers
          },
          first_name: customer && customer.first_name,
          last_name: customer && customer.last_name
        },
        items: this.RootStore.configStore.items ? this.RootStore.configStore.items.slice() : null,
        shipping: this.RootStore.configStore.shipping ? this.RootStore.configStore.shipping : null,
        taxes: this.RootStore.configStore.taxes ? this.RootStore.configStore.taxes.slice() : null,
        currency: mode != null && this.RootStore.configStore.order ? this.RootStore.configStore.order.currency : null,
        total_amount: mode != null && this.RootStore.configStore.order ? this.RootStore.configStore.order.amount : null,
        merchant_id: this.RootStore.configStore.merchantId ? this.RootStore.configStore.merchantId : null,
        ipaddress: this.RootStore.configStore.client_ip,
        hashstring: this.RootStore.configStore.transaction ? this.RootStore.configStore.transaction.hashstring : null,
        reference: this.RootStore.configStore.transaction ? this.RootStore.configStore.transaction.reference : null,
        post: {
          url: this.RootStore.configStore.transaction ? this.RootStore.configStore.transaction.post : null
        }
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null
    await axios
      .post(this.RootStore.serverPath + "/init", body)
      .then(async function (response) {
        res = response.data

        if (response.status == 200 && res.status === "success") {
          var merchant = res.merchant

          var payment_options = res.payment_options

          self.mode = merchant.live_mode ? merchant.live_mode : null

          self.RootStore.merchantStore.merchant = {
            id: merchant.id,
            name: merchant.name
          }

          // self.RootStore.uIStore.modal_bg_img =
          //   self.RootStore.configStore.backgroundImg &&
          //   self.RootStore.configStore.backgroundImg.url != null
          //     ? self.RootStore.configStore.backgroundImg.url
          //     : merchant.background.url;

          self.RootStore.merchantStore.pk = self.RootStore.configStore.gateway.publicKey
          self.RootStore.merchantStore.session = await merchant.session_token

          self.RootStore.paymentStore.status_display_duration = merchant.sdk_settings.status_display_duration
          self.RootStore.paymentStore.otp_resend_interval = merchant.sdk_settings.otp_resend_interval
          self.RootStore.paymentStore.otp_resend_attempts = merchant.sdk_settings.otp_resend_attempts

          self.RootStore.paymentStore.card_wallet = merchant.permission.card_wallet

          if (!self.RootStore.paymentStore.card_wallet) {
            self.RootStore.configStore.gateway.saveCardOption = false
            self.RootStore.configStore.transaction.saveCard = false
          }

          // self.RootStore.paymentStore.setThreeDSecure(
          //   merchant.permission.threeDSecure
          // );

          self.RootStore.merchantStore.setDetails(merchant)

          if (self.RootStore.configStore.transaction_mode != "token" && self.RootStore.configStore.transaction_mode != "save_card") {
            self.RootStore.paymentStore.getPaymentMethods(
              payment_options,
              self.RootStore.configStore.order ? self.RootStore.configStore.order.currency : null
            )
          } else {
            self.RootStore.applePayStore.isLoading = false
            self.RootStore.uIStore.stopLoading()
          }
        } else {
          self.sendResponse(response.data)
        }
      })
      .catch(function (error) {
        self.sendResponse(error)
      })

    self.updateLoader()
    return await res
  }

  sendResponse(json) {
    var self = this

    if (navigator.onLine) {
      if (json.errors) {
        self.RootStore.configStore.callbackFunc(json)

        if (json.errors[0] && json.errors[0].code == 2118) {
          self.RootStore.uIStore.showMsg(
            "warning",
            self.RootStore.localizationStore.getContent("session_error_msg", null),
            self.RootStore.localizationStore.getContent("err_code_is", null) + (json.errors[0] && json.errors[0].code)
          )
        } else {
          self.RootStore.uIStore.showMsg(
            "warning",
            self.RootStore.localizationStore.getContent("gosell_something_went_wrong", null),
            self.RootStore.localizationStore.getContent("err_code_is", null) + (json.errors[0] && json.errors[0].code)
          )
        }
      } else if (json.response) {
        self.RootStore.configStore.callbackFunc(json)

        self.RootStore.uIStore.showMsg(
          "warning",
          self.RootStore.localizationStore.getContent("gosell_something_went_wrong", null),
          self.RootStore.localizationStore.getContent("err_code_is", null) + json.response.code
        )
      } else {
        self.RootStore.configStore.callbackFunc(json)
        self.RootStore.uIStore.showMsg("error", self.RootStore.localizationStore.getContent("connection_issue_msg", null), null)
      }
    }
  }

  async createTransaction(publicKey) {
    var self = this

    var transaction = null
    await this.auth(self.RootStore.configStore.key).then(async (result) => {
      if (result.status === "success") {
        switch (self.RootStore.configStore.transaction_mode) {
          case "charge":
            transaction = await self.charge("src_all", null, null)
            break
          case "authorize":
            transaction = await self.authorize("src_all", null, null)
            break
        }
      } else {
        if (result.error || result.errors) {
          self.sendResponse(result)
        }
      }
    })

    return await transaction
  }

  async handleChargeResponse(chg) {
    var self = this
    if (chg.status == 200) {
      if (chg.data.status && chg.data.status.toUpperCase() === "INITIATED") {
        if (chg.data.transaction && chg.data.transaction.url) {
          window.top.location.href = chg.data.transaction.url
        } else if (chg.data.authenticate) {
          self.RootStore.paymentStore.transaction = chg.data
          self.RootStore.paymentStore.authenticate = chg.data.authenticate

          if (chg.data.authenticate.status === "INITIATED") {
            self.RootStore.uIStore.setPageIndex(2, "y")
            self.RootStore.uIStore.stopBtnLoader()
          }
        }
      } else if (chg.data.status && chg.data.status.toUpperCase() === "CAPTURED") {
        self.RootStore.configStore.callbackFunc(chg.data)

        var redirect = self.RootStore.configStore.generateRedirectURL(self.RootStore.configStore.redirect_btn, "popup", "tap_id=" + chg.data.id)

        window.parent.location.href = redirect
      } else if (chg.data.status && chg.data.status.toUpperCase() === "IN_PROGRESS") {
        /// do something
        self.RootStore.paymentStore.setFawryPay(chg.data)
        self.RootStore.uIStore.setFawryView()
      } else if (
        chg.data.status &&
        (chg.data.status.toUpperCase() === "ABANDONED" ||
          chg.data.status.toUpperCase() === "CANCELLED" ||
          chg.data.status.toUpperCase() === "FAILED" ||
          chg.data.status.toUpperCase() === "DECLINED" ||
          chg.data.status.toUpperCase() === "RESTRICTED" ||
          chg.data.status.toUpperCase() === "VOID" ||
          chg.data.status.toUpperCase() === "TIMEDOUT")
      ) {
        self.RootStore.configStore.callbackFunc(chg.data)

        var redirect = self.RootStore.configStore.generateRedirectURL(self.RootStore.configStore.redirect_btn, "popup", "tap_id=" + chg.data.id)

        window.parent.location.href = redirect
      } else {
        self.sendResponse(chg.data)
      }
    } else {
      self.sendResponse(chg.data)
    }
  }

  async handleAuthorizeResponse(auth) {
    var self = this
    if (auth.status == 200) {
      if (auth.data.status && auth.data.status.toUpperCase() === "INITIATED") {
        if (auth.data.transaction && auth.data.transaction.url) {
          window.top.location.href = auth.data.transaction.url
        } else if (auth.data.authenticate) {
          self.RootStore.paymentStore.transaction = auth.data
          self.RootStore.paymentStore.authenticate = auth.data.authenticate

          if (auth.data.authenticate.status === "INITIATED") {
            self.RootStore.uIStore.setPageIndex(2, "y")
            self.RootStore.uIStore.stopBtnLoader()
          }
        }
      } else if (
        auth.data.status &&
        (auth.data.status.toUpperCase() === "VOID" ||
          auth.data.status.toUpperCase() === "AUTHORIZED" ||
          auth.data.status.toUpperCase() === "CAPTURED")
      ) {
        self.RootStore.configStore.callbackFunc(auth.data)

        var redirect = self.RootStore.configStore.generateRedirectURL(self.RootStore.configStore.redirect_btn, "popup", "tap_id=" + auth.data.id)

        window.parent.location.href = redirect
      } else if (
        auth.data.status &&
        (auth.data.status.toUpperCase() === "ABANDONED" ||
          auth.data.status.toUpperCase() === "CANCELLED" ||
          auth.data.status.toUpperCase() === "FAILED" ||
          auth.data.status.toUpperCase() === "DECLINED" ||
          auth.data.status.toUpperCase() === "RESTRICTED" ||
          auth.data.status.toUpperCase() === "TIMEDOUT")
      ) {
        self.RootStore.configStore.callbackFunc(auth.data)

        var redirect = self.RootStore.configStore.generateRedirectURL(self.RootStore.configStore.redirect_btn, "popup", "tap_id=" + auth.data.id)

        window.parent.location.href = redirect
      } else {
        self.sendResponse(auth.data)
      }
    } else {
      self.sendResponse(auth.data)
    }
  }

  async handleTransaction(source, type, fees) {
    var transaction = null
    var self = this

    switch (this.RootStore.configStore.transaction_mode) {
      case "charge":
        transaction = this.charge(source, type, fees).then(async (chg) => {
          await self.handleChargeResponse(chg)

          return await chg.data
        })
        break
      case "authorize":
        transaction = this.authorize(source, type, fees).then(async (auth) => {
          await self.handleAuthorizeResponse(auth)
          return await auth.data
        })
        break
    }

    return await transaction
  }

  async updateTransaction({ id, ...charge }) {
    var self = this
    var app = this.RootStore.configStore.app
    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "PUT",
      path: "/v2/charges" + "/" + id,
      headers: headers,
      location: location,
      reqBody: {
        ...charge,
        hashstring: this.RootStore.configStore.transaction.hashstring,
        ipaddress: this.RootStore.configStore.client_ip
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }
    try {
      const response = await axios.post(self.RootStore.serverPath + "/api", body)
      await self.handleChargeResponse(response)
      await self.updateToken(self.RootStore.configStore.token)
    } catch (error) {
      if (error.response?.data?.errors) return self.sendResponse(error.response.data)
      throw new Error(error)
    }
  }
  async checkTransaction(id) {
    var self = this

    var transaction = null

    if (self.RootStore.merchantStore.session == null) {
      await this.auth(self.RootStore.configStore.key).then(async (result) => {
        if (result.status !== "success") {
          self.sendResponse(result)
        }
      })
    }

    var type = id.substring(0, 4)

    switch (type) {
      case "chg_":
        transaction = await self.getCharge(id)
        if ((transaction.data?.status || "").toUpperCase() !== "INITIATED") {
          await self.handleChargeResponse(transaction)
          await self.updateToken(self.RootStore.configStore.token)
          // close benefit pay window
          window.InApp?.close?.()
        }
        break
      case "auth":
        transaction = await self.getAuthorize(id)
        if ((transaction.data?.status || "").toUpperCase() !== "INITIATED") {
          await self.handleAuthorizeResponse(transaction)
          await self.updateToken(self.RootStore.configStore.token)
          // close benefit pay window
          window.InApp?.close?.()
        }
        break
    }

    return transaction
  }

  async charge(source, type, fees) {
    var self = this
    var app = this.RootStore.configStore.app
    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "POST",
      path: "/v2/charges",
      headers: headers,
      location: location,
      reqBody: {
        id: this.RootStore.configStore.transaction.id ? this.RootStore.configStore.transaction.id : null,
        amount: this.RootStore.configStore.order.amount,
        currency: this.RootStore.configStore.order.currency,
        public_key: this.RootStore.configStore.key,
        product: "GOSELL",
        threeDSecure: this.RootStore.paymentStore.three_d_Secure,
        save_card: this.RootStore.paymentStore.save_card_option,
        fee: fees,
        statement_descriptor: this.RootStore.configStore.transaction.statement_descriptor,
        description: this.RootStore.configStore.transaction.description,
        metadata: this.RootStore.configStore.transaction.metadata,
        reference: this.RootStore.configStore.transaction.reference,
        destinations: this.RootStore.configStore.transaction.destinations,
        receipt: this.RootStore.configStore.transaction.receipt,
        customer: this.RootStore.configStore.customer,
        source: {
          id: source
        },
        hashstring: this.RootStore.configStore.transaction.hashstring,
        post: {
          url: this.RootStore.configStore.transaction.post
        },
        redirect: {
          url: self.RootStore.configStore.generateRedirectURL(self.RootStore.configStore.redirect_btn, "popup", null)
        },
        ipaddress: this.RootStore.configStore.client_ip,
        selected_currency: this.RootStore.paymentStore.current_currency.currency,
        selected_amount: this.RootStore.paymentStore.current_currency.amount,
        merchant: {
          id: this.RootStore.configStore.merchantId ? this.RootStore.configStore.merchantId : null
        }
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }
    const order_id = this.RootStore.paymentStore.order_id
    if (order_id) {
      body.reqBody.order = {
        id: order_id
      }
    }

    var res = null

    await axios
      .post(self.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response
        if (source !== "src_bh.benefitpay") await self.updateToken(self.RootStore.configStore.token)
      })
      .catch(function (error) {})

    return res
  }

  async authorize(source, type, fees) {
    var self = this

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "POST",
      path: "/v2/authorize",
      headers: headers,
      location: location,
      reqBody: {
        id: this.RootStore.configStore.transaction.id ? this.RootStore.configStore.transaction.id : null,
        amount: this.RootStore.configStore.order.amount,
        currency: this.RootStore.configStore.order.currency,
        public_key: this.RootStore.configStore.key,
        product: "GOSELL",
        threeDSecure: this.RootStore.paymentStore.three_d_Secure,
        save_card: this.RootStore.paymentStore.save_card_option,
        fee: fees,
        statement_descriptor: this.RootStore.configStore.transaction.statement_descriptor,
        description: this.RootStore.configStore.transaction.description,
        metadata: this.RootStore.configStore.transaction.metadata,
        reference: this.RootStore.configStore.transaction.reference,
        destinations: this.RootStore.configStore.transaction.destinations,
        receipt: this.RootStore.configStore.transaction.receipt,
        customer: this.RootStore.configStore.customer,
        source: {
          id: source
        },
        auto: {
          type: this.RootStore.configStore.transaction.auto.type,
          time: this.RootStore.configStore.transaction.auto.time
        },
        hashstring: this.RootStore.configStore.transaction.hashstring,
        post: {
          url: this.RootStore.configStore.transaction.post
        },
        redirect: {
          url: self.RootStore.configStore.generateRedirectURL(self.RootStore.configStore.redirect_btn, "popup", null)
        },
        selected_currency: this.RootStore.paymentStore.current_currency.currency,
        selected_amount: this.RootStore.paymentStore.current_currency.amount,
        merchant: {
          id: this.RootStore.configStore.merchantId ? this.RootStore.configStore.merchantId : null
        },
        ipaddress: this.RootStore.configStore.client_ip
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }
    const order_id = this.RootStore.paymentStore.order_id
    if (order_id) {
      body.reqBody.order = {
        id: order_id
      }
    }

    var res = null

    await axios
      .post(self.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response
        await self.updateToken(self.RootStore.configStore.token, (result) => {})
      })
      .catch(function (error) {})
    return res
  }

  async getTransaction(id) {
    var self = this

    var transaction = null

    if (self.RootStore.merchantStore.session == null) {
      await this.auth(self.RootStore.configStore.key).then(async (result) => {
        if (result.status !== "success") {
          self.sendResponse(result)
        }
      })
    }

    var type = id.substring(0, 4)

    switch (type) {
      case "chg_":
        transaction = await self.getCharge(id)
        break
      case "auth":
        transaction = await self.getAuthorize(id)
        break
    }

    if (transaction.data.error || transaction.data.errors) {
      self.sendResponse(transaction.data)
    }

    return transaction
  }

  async getTransactionResult(id) {
    var self = this

    var transaction = null

    if (self.RootStore.merchantStore.session == null) {
      await this.auth(self.RootStore.configStore.key).then(async (result) => {
        if (result.status !== "success") {
          self.sendResponse(result)
        }
      })
    }

    var type = id.substring(0, 4)

    switch (type) {
      case "chg_":
        await self.getCharge(id).then(async (charge) => {
          if (charge.status == 200) {
            transaction = charge
            if (charge.data.status && charge.data.status.toUpperCase() === "INITIATED") {
              window.top.location.href = charge.data.transaction.url
            } else if (charge.data.status && charge.data.status.toUpperCase() === "CAPTURED") {
              self.RootStore.configStore.callbackFunc(charge.data)
              self.RootStore.uIStore.showMsg(
                "success",
                self.RootStore.localizationStore.getContent("gosell_successful_transaction", null),
                self.RootStore.localizationStore.getContent("res_id", null) + charge.data.id
              )
            } else if (charge.data.status && charge.data.status.toUpperCase() === "IN_PROGRESS") {
              /// do something
              self.RootStore.paymentStore.setFawryPay(chg.data)
              self.RootStore.uIStore.setFawryView()
            } else if (
              charge.data.status &&
              (charge.data.status.toUpperCase() === "ABANDONED" ||
                charge.data.status.toUpperCase() === "CANCELLED" ||
                charge.data.status.toUpperCase() === "FAILED" ||
                charge.data.status.toUpperCase() === "DECLINED" ||
                charge.data.status.toUpperCase() === "RESTRICTED" ||
                charge.data.status.toUpperCase() === "VOID" ||
                charge.data.status.toUpperCase() === "TIMEDOUT")
            ) {
              self.sendResponse(charge.data)
              self.RootStore.uIStore.showMsg(
                "error",
                self.RootStore.localizationStore.getContent("gosell_failed_transaction", null),
                self.RootStore.localizationStore.getContent("res_id", null) + charge.data.id
              )
            } else {
              self.sendResponse(charge.data)
            }
          } else {
            //
            self.sendResponse(charge.data)
          }
        })
        break
      case "auth":
        await self.getAuthorize(id).then(async (auth) => {
          if (auth.status == 200) {
            transaction = auth

            if (auth.data.status && auth.data.status.toUpperCase() === "INITIATED") {
              window.top.location.href = auth.data.transaction.url
            } else if (
              auth.data.status &&
              (auth.data.status.toUpperCase() === "VOID" ||
                auth.data.status.toUpperCase() === "CAPTURED" ||
                auth.data.status.toUpperCase() === "AUTHORIZED")
            ) {
              self.RootStore.configStore.callbackFunc(auth.data)
              self.RootStore.uIStore.showMsg(
                "success",
                self.RootStore.localizationStore.getContent("gosell_successful_transaction", null),
                self.RootStore.localizationStore.getContent("res_id", null) + auth.data.id
              )
            } else if (
              auth.data.status &&
              (auth.data.status.toUpperCase() === "ABANDONED" ||
                auth.data.status.toUpperCase() === "CANCELLED" ||
                auth.data.status.toUpperCase() === "FAILED" ||
                auth.data.status.toUpperCase() === "DECLINED" ||
                auth.data.status.toUpperCase() === "RESTRICTED" ||
                auth.data.status.toUpperCase() === "TIMEDOUT")
            ) {
              self.sendResponse(auth.data)
              self.RootStore.uIStore.showMsg(
                "error",
                self.RootStore.localizationStore.getContent("gosell_failed_transaction", null),
                self.RootStore.localizationStore.getContent("res_id", null) + auth.data.id
              )
            } else {
              self.sendResponse(auth.data)
            }
          } else {
            self.sendResponse(auth.data)
          }
        })
        break
    }

    return await transaction
  }

  async getCharge(chg_id) {
    var self = this

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "GET",
      path: "/v2/charges/" + chg_id,
      headers: headers,
      location: location,
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null
    await axios
      .post(this.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response
      })
      .catch(function (error) {
        return error
      })

    return res
  }

  async getAuthorize(auth_id) {
    var self = this

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "GET",
      path: "/v2/authorize/" + auth_id,
      headers: headers,
      location: location,
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null
    await axios
      .post(this.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response
      })
      .catch(function (error) {
        return error
      })

    return await res
  }

  async deleteCard(card_id, index) {
    var self = this

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "DELETE",
      path: "/v2/card/" + this.RootStore.configStore.customer.id + "/" + card_id,
      headers: headers,
      location: location,
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null
    await axios
      .post(this.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response.data

        if (response.status == 200 && (res.error || res.errors)) {
          self.sendResponse(response.data)
        }
      })
      .catch(function (error) {})

    return await res
  }

  async updateCards() {
    var self = this

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "POST",
      path: "/v2/payment/types",
      headers: headers,
      location: location,
      reqBody: {
        customer: this.RootStore.configStore.customer.id ? this.RootStore.configStore.customer.id : null,
        currency: this.RootStore.paymentStore.current_currency.currency,
        total_amount: this.RootStore.paymentStore.current_currency.amount,
        ipaddress: this.RootStore.configStore.client_ip
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null
    var result = null

    await axios
      .post(this.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response.data

        if (response.status == 200) {
          //

          if (response.data.error || response.data.errors) {
            self.RootStore.uIStore.setErrorHandler({
              visable: true,
              code: "error",
              msg: self.RootStore.localizationStore.getContent("gosell_something_went_wrong", null),
              type: "error"
            })
          } else {
            setTimeout(function () {
              self.RootStore.uIStore.setErrorHandler({
                visable: true,
                code: "success",
                msg: self.RootStore.localizationStore.getContent("card_deleted_successfully", null),
                type: "success"
              })
            }, 200)
          }
        } else {
          //

          self.RootStore.uIStore.setErrorHandler({
            visable: true,
            code: "error",
            msg: self.RootStore.localizationStore.getContent("gosell_something_went_wrong", null),
            type: "error"
          })
        }
      })
      .catch(function (error) {})

    return await res
  }

  async getSavedCardToken(card) {
    var self = this

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "POST",
      path: "/v2/tokens",
      headers: headers,
      location: location,
      reqBody: {
        saved_card: {
          card_id: card,
          customer_id: this.RootStore.configStore.customer.id
        },
        ipaddress: this.RootStore.configStore.client_ip
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var result,
      res = null
    await axios
      .post(this.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response

        if (res.status != 200 || res.data.error || res.data.errors || res.status == "fail") {
          self.sendResponse(res.data)
        }
        // else{
        //   var error = res.data;
        //   //
        //
        //   self.RootStore.uIStore.setErrorHandler({
        //     visable: true,
        //     code: error.status,
        //     msg: self.RootStore.localizationStore.getContent('gosell_something_went_wrong', null),
        //     type: 'error'
        //   });
        //
        // }
      })
      .catch(function (error) {})

    return await res
  }

  async createCustomer() {
    var self = this

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "POST",
      path: "/v2/customers",
      headers: headers,
      location: location,
      reqBody: {
        first_name: this.RootStore.configStore.customer.first_name,
        middle_name: this.RootStore.configStore.customer.middle_name,
        last_name: this.RootStore.configStore.customer.last_name,
        email: this.RootStore.configStore.customer.email,
        phone: {
          country_code: this.RootStore.configStore.customer.phone.country_code,
          number: this.RootStore.configStore.customer.phone.number
        },
        ipaddress: this.RootStore.configStore.client_ip
        // "description": this.RootStore.configStore.description,
        // "metadata": this.RootStore.configStore.metadata,
        // "currency": this.RootStore.paymentStore.current_currency.currency
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null
    await axios
      .post(this.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response

        if (res.status == 200) {
          if (res.data.error || res.data.errors) {
            self.sendResponse(res.data)
          } else {
            self.RootStore.configStore.customer = res.data
          }
        } else {
          self.sendResponse(res.data)
        }
      })
      .catch(function (error) {})
    return await res
  }

  async saveCustomerCard(token) {
    var self = this

    // self.RootStore.uIStore.startLoading('loader', 'Please Wait', null);
    //var customer = null;
    if (this.RootStore.configStore.customer && this.RootStore.configStore.customer.id) {
      var customer = this.RootStore.configStore.customer
      this.createCard(customer.id, token)
    } else {
      this.createCustomer().then((result) => {
        if (result.status == 200) {
          if (result.error || result.errors) {
            self.sendResponse(res.data)
          } else {
            self.createCard(self.RootStore.configStore.customer.id, token)
          }
        }
      })
    }
  }

  async createCard(customer_id, token) {
    var self = this

    self.RootStore.uIStore.startLoading("loader", self.RootStore.localizationStore.getContent("please_wait_msg", null), null)

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "POST",
      path: "/v2/card/" + customer_id,
      headers: headers,
      location: location,
      reqBody: {
        source: token,
        ipaddress: this.RootStore.configStore.client_ip
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null

    await axios
      .post(this.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response.data

        //

        if (response.status == 200) {
          //

          if (res.error || res.errors) {
            self.sendResponse(res.data)
          } else if (res.status == "fail") {
            self.sendResponse(res)
          } else {
            self.RootStore.configStore.callbackFunc(res)
            self.RootStore.uIStore.showMsg(
              "success",
              self.RootStore.localizationStore.getContent("card_has_been_saved", null),
              self.RootStore.localizationStore.getContent("res_id", null) + res.id
            )
          }
        } else {
          //
          self.sendResponse(response.data)
        }
      })
      .catch(function (error) {})

    return await res
  }

  async authentication(type, value) {
    var self = this

    var path = null
    switch (this.RootStore.configStore.transaction_mode) {
      case "charge":
        path = "/v2/charges/authenticate/" + this.RootStore.paymentStore.transaction.id
        break
      case "authorize":
        path = "/v2/authorize/authenticate/" + this.RootStore.paymentStore.transaction.id
        break
    }

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "POST",
      path: path,
      headers: headers,
      location: location,
      reqBody: {
        type: type,
        value: value,
        ipaddress: this.RootStore.configStore.client_ip
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null
    await axios
      .post(this.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response.data

        if (response.status == 200) {
          //
          //
          if (res.status === "INITIATED" && res.transaction.url) {
            // window.open(res.transaction.url, '_self');
            window.top.location.href = res.transaction.url
          } else if (
            res.status === "CAPTURED" ||
            (self.RootStore.configStore.transaction_mode == "authorize" && (res.status === "VOID" || res.status === "AUTHORIZED"))
          ) {
            self.RootStore.configStore.callbackFunc(response.data)

            var redirect = self.RootStore.configStore.generateRedirectURL(self.RootStore.configStore.redirect_btn, "popup", "tap_id=" + res.id)

            window.parent.location.href = redirect

            // self.RootStore.uIStore.showMsg(
            //   "success",
            //   self.RootStore.localizationStore.getContent(
            //     "gosell_successful_transaction",
            //     null
            //   ),
            //   res.id
            // );
          } else {
            self.sendResponse(response.data)
            var redirect = self.RootStore.configStore.generateRedirectURL(self.RootStore.configStore.redirect_btn, "popup", "tap_id=" + res.id)

            window.parent.location.href = redirect
            // self.RootStore.uIStore.showMsg(
            //   "error",
            //   self.RootStore.localizationStore.getContent(
            //     "gosell_failed_transaction",
            //     null
            //   ),
            //   res.id
            // );
          }
        } else {
          //
          self.sendResponse(response.data)
          var redirect = self.RootStore.configStore.generateRedirectURL(self.RootStore.configStore.redirect_btn, "popup", "tap_id=" + res.id)

          window.parent.location.href = redirect
          // self.RootStore.uIStore.showMsg(
          //   "error",
          //   self.RootStore.localizationStore.getContent(
          //     "gosell_failed_transaction",
          //     null
          //   ),
          //   res.id
          // );
        }
      })
      .catch(function (error) {})

    return await res
  }

  async requestAuthentication(type, value) {
    var self = this

    var path = null
    switch (this.RootStore.configStore.transaction_mode) {
      case "charge":
        path = "/v2/charges/authenticate/" + this.RootStore.paymentStore.transaction.id
        break
      case "authorize":
        path = "/v2/authorize/authenticate/" + this.RootStore.paymentStore.transaction.id
        break
    }

    var app = this.RootStore.configStore.app

    var headers = {
      session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var body = {
      mode: this.APP_MODE,
      method: "PUT",
      path: path,
      headers: headers,
      location: location,
      user_agent: app.user_agent ? app.user_agent : null,
      token: this.RootStore.configStore.token
    }

    var res = null
    await axios
      .post(this.RootStore.serverPath + "/api", body)
      .then(async function (response) {
        res = response.data
        //

        if (response.status == 200) {
          //

          if (res.status === "DECLINED" || res.error || res.errors) {
            self.RootStore.uIStore.setErrorHandler({})
            self.sendResponse(response.data)
          } else {
            self.RootStore.uIStore.setErrorHandler({
              visable: true,
              code: res.response.code,
              msg: self.RootStore.localizationStore.getContent("otp_code_sent", null),
              type: "success"
            })
          }
        } else {
          self.RootStore.uIStore.setErrorHandler({})
          self.sendResponse(response.data)
        }
      })
      .catch(function (error) {})

    return await res
  }

  async getIP() {
    // if the call is not done in 1.5 seconds, it will be aborted
    var timeout = 1500
    var res = null
    await axios
      .get("https://api.ipify.org?format=jsonp&callback=", {
        headers: {
          "Content-Type": "application/json"
        },
        timeout
      })
      .then(async function (response) {
        //
        if (response.status == 200) {
          res = eval(response.data).ip
        }
      })
      .catch(function (error) {})

    return res
  }

  async getCurrentCountry() {
    var self = this

    var app = this.RootStore.configStore.app

    var headers = {
      // session_token: self.RootStore.merchantStore.session,
      Application:
        "app_locale=" +
        app.app_locale +
        "|requirer=" +
        app.requirer +
        "|app_id=" +
        app.app_id +
        "|app_client_version=" +
        app.app_client_version +
        "|app_server_version=" +
        app.app_server_version +
        "|requirer_os=" +
        app.requirer_os +
        "|requirer_os_version=" +
        app.requirer_os_version +
        "|requirer_browser=" +
        app.requirer_browser +
        "|requirer_browser_version=" +
        app.requirer_browser_version
    }

    var location = this.RootStore.configStore.location

    var res = null

    var body = {
      method: "GET",
      headers: headers,
      location: location,
      params: {
        ip: self.RootStore.configStore.client_ip
      },
      user_agent: app.user_agent ? app.user_agent : null,
      token: self.RootStore.configStore.token
    }

    await axios
      .post(self.RootStore.serverPath + "/currency", body)
      .then(async function (response) {
        //
        if (response.status == 200) {
          res = response.data
        }
      })
      .catch(function (error) {})

    return await res
  }
}

decorate(ApiStore, {})

export default ApiStore
