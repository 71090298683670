import ApiStore from "./ApiStore.js"
import PaymentStore from "./PaymentStore.js"
import MerchantStore from "./MerchantStore.js"
import UIStore from "./UIStore.js"
import ConfigStore from "./ConfigStore.js"
import ActionStore from "./ActionStore.js"
import LocalizationStore from "./LocalizationStore.js"
import FormStore from "./FormStore.js"
import ApplePayStore from "./ApplePayStore.js"
import LogStore from "./LogStore.js"
import GooglePayStore from "./GooglePay.js"
import BenefitPayStore from "./BenefitPayStore.js"

class RootStore {
  constructor() {
    if (!window._babelPolyfill) {
      require("@babel/polyfill")
    }

    if (window.location.hostname === "localhost") {
      this.serverPath = window.location.protocol + "//" + window.location.hostname + ":8000"
    } else {
      this.serverPath = window.location.protocol + "//" + window.location.hostname + "/api"
    }

    this.localizationStore = new LocalizationStore(this)
    this.configStore = new ConfigStore(this)
    this.apiStore = new ApiStore(this)
    this.paymentStore = new PaymentStore(this)
    this.merchantStore = new MerchantStore(this)
    this.actionStore = new ActionStore(this)
    this.formStore = new FormStore(this)
    this.uIStore = new UIStore(this)
    this.applePayStore = new ApplePayStore(this)
    this.logStore = new LogStore(this)
    this.googlePayStore = new GooglePayStore(this)
    this.benefitPayStore = new BenefitPayStore(this)
  }
}

let Store = new RootStore()
export default Store
