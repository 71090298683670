import React from "react"
import "../assets/css/benefitPay.css"

function BenefitPay({ store }) {
  const { InApp } = window

  const onCloseCallback = () => {
    // InApp.close()
    store.benefitPayStore.onClose()
  }
  const onSuccessCallback = (response) => {
    store.benefitPayStore.onSuccess(response)
  }
  const onErrorCallback = (error) => {
    store.benefitPayStore.onError(error)
  }
  const onClick = async () => {
    const request = await store.benefitPayStore.createTBenefitPayTransaction()
    if (!request) return
    InApp.open(request, onSuccessCallback, onErrorCallback, onCloseCallback)
  }

  if (!InApp) return null
  if (!store.benefitPayStore.isSupported) return null
  const dir = store.configStore.language?.toLowerCase() === "ar" ? "rtl" : "ltr"
  const logoURL =
    dir === "rtl" ? "https://back-end.b-cdn.net/payment_methods/benefit-pay-ar.svg" : "https://back-end.b-cdn.net/payment_methods/benefit-pay-en.svg"

  return (
    <div className={`benefit-pay-container`}>
      <button className='benefit-pay-button' onClick={() => onClick()} style={{ direction: dir }}>
        <img className='benefit-pay-button-image' src={logoURL} alt='benefit-pay' />
      </button>
    </div>
  )
}

export default BenefitPay
