import { decorate, computed, action, observable } from "mobx"

class BenefitPayStore {
  APP_MODE = process.env.APP_MODE
  transaction = null
  interval = null
  intervalTimeInMS = 1000 * 60
  constructor(RootStore) {
    this.RootStore = RootStore
  }

  get isSupported() {
    const item = this.getPaymentMethodData()
    const { currency } = this.RootStore.paymentStore.current_currency
    return item && item.supported_currencies.includes(currency)
  }

  getPaymentMethodData() {
    const { payment_methods } = this.RootStore.paymentStore.paymentOptions
    return payment_methods?.find(({ name, source_id }) => (name || "").toLowerCase() === "benefitpay" || source_id === "src_benefit_pay")
  }
  handleLog(type, data) {
    this.RootStore.logStore.handleConfigLog(type, data)
  }

  handleErrorMsg(code) {
    const clientMsg = this.RootStore.localizationStore.getContent("gosell_something_went_wrong" + code, null)
    const callbackMsg = this.RootStore.localizationStore.getContent("gosell_something_went_wrong" + code, "en")
    this.RootStore.logStore.handleConfigLog(code, callbackMsg)
    this.RootStore.uIStore.showMsg("warning", clientMsg, this.RootStore.localizationStore.getContent("err_code_is", null) + code)
    this.RootStore.uIStore.stopLoading()
  }

  handleOnError(error) {
    this.handleErrorMsg("0005")
    this.handleLog("ON_ERROR", error)
    this.RootStore.uIStore.stopLoading()
  }

  async setSource(source, paymentName) {
    const res = await this.RootStore.paymentStore.setActivePaymentOption(paymentName, source)
    if (!res) throw new Error("set source failed")
    this.handleLog("BENEFIT_PAY_ACTIVE_PAYMENT", res)
  }

  async handleTransaction() {
    try {
      const sourceId = this.RootStore.paymentStore.source_id
      this.handleLog("SOURCE_ID", sourceId)
      return await this.RootStore.apiStore.handleTransaction(sourceId, "BENEFIT_PAY", this.RootStore.paymentStore.active_payment_option_fees)
    } catch (e) {
      throw new Error("Failed to create transaction")
    }
  }
  startIntervalTransactionChecks() {
    this.interval = setInterval(() => {
      this.RootStore.apiStore.checkTransaction(this.transaction.id)
    }, this.intervalTimeInMS)
  }
  async createTBenefitPayTransaction() {
    setTimeout(() => this.clearIntervalChecks(), 1000 * 60 * 11)
    const payment = this.getPaymentMethodData()
    this.handleLog("ON_PAYMENT_AUTHORIZED", payment)
    this.RootStore.uIStore.startLoading("loader", this.RootStore.localizationStore.getContent("please_wait_msg", null), null)
    try {
      await this.setSource(payment.source_id, payment.name)
      const trans = await this.handleTransaction()
      this.transaction = trans
      const { amount, hash, currency, reference, merchant, application, configuration } = trans.gateway_response.request
      const { interval } = configuration.frequency || {}
      if (interval) {
        this.intervalTimeInMS = interval * 1000
      }
      this.RootStore.uIStore.setLoaderStatus(false)
      this.RootStore.uIStore.setModalEmpty(true)
      this.startIntervalTransactionChecks()
      return {
        merchantId: merchant.id,
        transactionAmount: amount,
        transactionCurrency: currency,
        appId: application.id,
        referenceNumber: reference.transaction,
        showResult: configuration.showResult,
        hideMobileQR: configuration.hideMobileQR,
        secure_hash: hash
      }
    } catch (error) {
      // this.RootStore.uIStore.setLoaderStatus(false)
      // setTimeout(() => {
      //   this.RootStore.uIStore.setModalEmpty(false)
      // }, 1000)
      // this.RootStore.uIStore.setOpenModal(false)
      // this.handleErrorMsg("2120", error?.message || "Failed to create transaction")
      this.handleLog("STATUS_FAILED", error?.message || "Failed to create transaction")
    }
  }
  clearIntervalChecks() {
    clearInterval(this.interval)
  }

  onClose() {
    this.RootStore.uIStore.setModalEmpty(false)
    this.handleLog("ON_CANCEL", "benefit popup has been closed")
    this.clearIntervalChecks()
  }
  async onSuccess(res) {
    InApp?.close?.()
    this.clearIntervalChecks()
    this.RootStore.uIStore.startLoading("loader", this.RootStore.localizationStore.getContent("please_wait_msg", null), null)
    try {
      const cardLast4Digits = res.cardNumber.slice(-4)
      const cardFirst6Digits = res.cardNumber.slice(0, 6)
      const chargeRequest = {
        id: this.transaction.id,
        gateway_response: {
          name: "BENEFITPAY",
          response: {
            amount: res.orderAmount,
            currency: res.orderCurrency,
            status: res.transactionStatus,
            hash: res.secureHash,
            reference: {
              transaction: res.referenceNumber
            },
            merchant: {
              id: res.merchantId
            },
            card: {
              first_six: cardFirst6Digits,
              last_four: cardLast4Digits,
              brand: res.cardBrand
            }
          }
        }
      }
      if (res.errorCode) {
        chargeRequest.gateway_response.error = {
          code: res.errorCode,
          description: res.errorDescription
        }
      }

      await this.RootStore.apiStore.updateTransaction(chargeRequest)
    } catch (error) {
      this.handleLog("STATUS_FAILED", error?.message || "Failed to update charge")
    }
  }
  onError(res) {
    InApp?.close?.()
    this.RootStore.uIStore.setModalEmpty(true)
    // alert("onError")
    // alert(JSON.stringify(res))
    this.handleOnError(res)
    this.clearIntervalChecks()
  }
}

decorate(BenefitPayStore, {
  onError: action,
  onSuccess: action,
  onClosed: action,
  isSupported: computed,
  transaction: observable
})

export default BenefitPayStore
