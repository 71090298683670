import React, { Component } from "react"
import { observer } from "mobx-react"
import "../assets/css/applePay.css"

class ApplePay extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.store.applePayStore.isSupported) return null
    return (
      <div
        id='apple-pay-btn'
        lang={this.props.store.configStore.language ? this.props.store.configStore.language : "en"}
        className={`apple-pay-button apple-pay-button-white`}
        onClick={(e) => this.props.store.applePayStore.requestPayment()}
      ></div>
    )
  }
}

export default observer(ApplePay)
