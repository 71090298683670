import React from "react";
import "../assets/css/fawry.css";

class FawryPay extends React.Component {
  componentDidMount() {
    this.props.store.uIStore.calcElementsHeight("gosell-gateway-fawry-pay");
  }

  render() {
    var props = this.props;

    var msg =
      props.type == "both"
        ? props.bothMsgs.split("\n")
        : props.type == "sms"
        ? props.smsMsg.split("\n")
        : props.emailMsg.split("\n");

    var expiry = props.expiry.split("\n").map((line, index) => {
      return (
        <React.Fragment key={index}>
          {line} <br />
        </React.Fragment>
      );
    });

    var locationRef = props.locations.split("\n").map((line, index) => {
      return (
        <React.Fragment key={index}>
          {line.indexOf("http") != -1 ? (
            <a
              className="gosell-gateway-fawry-pay-link"
              href={line}
              target="_blank"
            >
              {line}
            </a>
          ) : (
            <React.Fragment>
              {line}
              <br />
            </React.Fragment>
          )}
        </React.Fragment>
      );
    });

    return (
      <div
        id="gosell-gateway-fawry-pay"
        className="gosell-gateway-fawry-pay-container"
        style={{ direction: props.dir, height: props.store.uIStore.mainHeight }}
      >
        <div className="gosell-gateway-fawry-pay-status">{props.statusMsg}</div>

        <div className="gosell-gateway-fawry-pay-msg">
          {msg[0]} <br />
          <div className="gosell-gateway-fawry-pay-phone-number">{msg[1]}</div>
          {msg[2]} <br />
        </div>

        <div className="gosell-gateway-fawry-pay-order-code-title">
          {props.orderCodeTitle}
        </div>

        <div className="gosell-gateway-fawry-pay-order-code">
          {props.orderCode}
        </div>

        <div className="gosell-gateway-fawry-pay-expiry">{expiry}</div>

        <div className="gosell-gateway-fawry-pay-locations">{locationRef}</div>
      </div>
    );
  }
}

export default FawryPay;
