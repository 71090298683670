import React from "react";
import "../assets/css/footer.css";

const Footer = (props) => {
  return (
    <div className="gosell-gateway-footer-container">
      <a
        className="gosell-gateway-footer-url"
        onClick={props.onClick}
        href={props.url}
      >
        {props.text}
      </a>
    </div>
  );
};

export default Footer;
