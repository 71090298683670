import React, { Component } from "react";
import GoSell from "./elements/GoSell";

class Popup extends Component {
  componentWillMount() {
    var URLSearchParams = require("@ungap/url-search-params/cjs");

    var urlParams = new URLSearchParams(window.location.search);

    var mode = urlParams.get("mode");

    switch (mode) {
      case "popup":
        GoSell.openLightBox();
        break;
      case "page":
        GoSell.generateTapGateway();
        break;
    }
  }

  render() {
    return (
      <div className="App">
        <GoSell />
      </div>
    );
  }
}

export default Popup;
