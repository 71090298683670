import * as mobx from "mobx"
// import axios from "axios";

const withTimeout = (millis, promise) => {
  const timeout = new Promise((resolve, reject) => setTimeout(() => console.log(`Timed out after ${millis} ms.`), millis))
  return Promise.race([promise, timeout])
}

class PaymentStore {
  constructor(RootStore) {
    this.RootStore = RootStore
    this.reset()

    this.confirmExchangeCurrency = this.confirmExchangeCurrency.bind(this)
    this.cancelExchangeCurrency = this.cancelExchangeCurrency.bind(this)
  }

  reset() {
    this.order_id = null
    this.payment_methods = {}
    this.current_amount = 0
    this.current_currency = {}
    this.settlement_currency = null
    this.old_currency = null

    //gcc currrencies list
    this.gcc = ["BHD", "SAR", "AED", "OMR", "QAR", "KWD"]

    // currencies list from config store.
    this.currencies = []

    //supported currenices for the selected payment methods
    this.supported_currencies_based_on_methods = []

    //filtered list of supported currencies
    this.supported_currencies = {}
    this.filtered_currencies = {}

    this.customer_cards = []
    this.customer_cards_by_currency = []
    this.selected_card = null

    this.webPayments = []
    this.cardPayments = []
    this.applePay = []

    this.redirectURL = null

    this.mode = null
    this.url = null

    //save card option
    this.card_wallet = false
    this.save_card_active = false
    this.save_card_option = false

    //3d secure
    this.three_d_Secure = false

    this.isLoading = true

    this.transaction = null

    //otp
    this.status_display_duration = 0
    this.otp_resend_interval = 0
    this.otp_resend_attempts = 0

    this.authenticate = null

    // card token details
    this.active_payment_option = null
    this.active_payment_option_fees = 0.0
    this.active_payment_option_total_amount = 0.0
    this.source_id = null

    this.transaction = null

    this.supported_payment_methods = null

    this.fawryPay = null

    this.isApplePayAvailable = false
  }

  setFawryPay(value) {
    var timestamp = parseInt(value.transaction.created) + parseInt(value.transaction.expiry.period)
    // var phoneFromConfig = this.RootStore.configStore.customer.phone ? '+' + this.RootStore.configStore.customer.phone.country_code + ' ' + this.RootStore.configStore.customer.phone.number : null;
    var phone = value.customer.phone ? "+" + value.customer.phone.country_code + " " + value.customer.phone.number : null
    var email = value.customer.email ? value.customer.email : null

    this.fawryPay = {
      orderCode: value.transaction.order.reference,
      type: phone != null && email != null ? "both" : phone != null ? "sms" : "email",
      phoneNumber: phone != null ? phone : email,
      expiryDate: new Date(timestamp).toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      }), //ar-EG
      expiryTime: new Date(timestamp).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit"
      }),
      location: value.transaction.order.store_url ? value.transaction.order.store_url : "https://fawry.com/storelocator/"
    }
  }

  setThreeDSecure() {
    var self = this

    var active_payment_option = this.RootStore.paymentStore.active_payment_option

    if (active_payment_option && active_payment_option.threeDS) {
      switch (active_payment_option.threeDS.toUpperCase()) {
        case "Y":
          self.three_d_Secure = true
          break
        case "N":
          self.three_d_Secure = false
          break
        case "U":
          self.setThreeDSFromConfig()
          break
      }
    }
  }

  setThreeDSFromConfig() {
    var self = this
    switch (self.RootStore.configStore.transaction_mode) {
      case "charge":
        self.three_d_Secure = self.RootStore.configStore.transaction ? self.RootStore.configStore.transaction.threeDSecure : true
        break
      case "authorize":
        self.three_d_Secure = self.RootStore.configStore.transaction ? self.RootStore.configStore.transaction.threeDSecure : true
        break
    }
  }

  setCards(value) {
    if (this.RootStore.configStore.gateway.customerCards) {
      this.customer_cards = value
      this.customer_cards_by_currency = this.savedCardsByCurrency
    }
  }

  getFees(paymentType) {
    var self = this

    if (self.payment_methods[paymentType] != undefined) {
      console.log("getFees", self.payment_methods[paymentType])

      var payment = self.payment_methods[paymentType]

      if (payment.extra_fees) {
        var total_extra_fees = self.calcExtraFees(payment.extra_fees)
        console.log("fee total", total_extra_fees)
        self.active_payment_option_fees = total_extra_fees
        self.active_payment_option_total_amount = self.current_currency.amount + self.active_payment_option_fees
      } else {
        self.active_payment_option_fees = 0
        self.active_payment_option_total_amount = self.current_currency.amount
      }
    }
  }

  // calculate fees
  calcExtraFees(fees) {
    var self = this
    var total_fees = 0

    var extra_fee = 0
    var fee = fees[0]

    if (fee.type.toUpperCase() === "P") {
      extra_fee = self.current_currency.amount / (1 - fee.value / 100) - self.current_currency.amount

      if (fee.minimum_fee && fee.minimum_fee != 0 && fee.maximum_fee && fee.maximum_fee != 0) {
        if (extra_fee >= fee.minimum_fee && extra_fee <= fee.maximum_fee) {
          total_fees = extra_fee
        } else if (extra_fee < fee.minimum_fee) {
          total_fees = fee.minimum_fee
        } else if (extra_fee > fee.maximum_fee) {
          total_fees = fee.maximum_fee
        }
      } else {
        total_fees = extra_fee
      }
    } else {
      if (self.settlement_currency && self.settlement_currency.currency == self.current_currency.currency) {
        extra_fee = fee.value
      } else {
        var rate = self.settlement_currency.amount / self.current_currency.amount
        extra_fee = fee.value * rate
      }

      total_fees = extra_fee
    }

    return total_fees
  }

  async setActivePaymentOption(paymentType, source_id) {
    if (this.payment_methods[paymentType]) {
      this.active_payment_option = await this.payment_methods[paymentType]
      await this.getFees(paymentType)
      this.source_id = await source_id

      await this.setThreeDSecure()

      return true
    } else if (this.RootStore.configStore.transaction_mode === "token" || this.RootStore.configStore.transaction_mode === "save_card") {
      this.source_id = await source_id
      return true
    } else {
      var msg = this.RootStore.localizationStore.getContent("supported_payment_method_error", "en").replace("{PAYMENT_TYPE}", paymentType)

      await this.RootStore.logStore.handleConfigLog(0, msg)

      await this.RootStore.uIStore.showMsg(
        "warning",
        this.RootStore.localizationStore.getContent("supported_payment_method_error", null).replace("{PAYMENT_TYPE}", paymentType),
        null
      )

      return false
    }
  }

  getPaymentMethods(data, currency) {
    var self = this
    this.order_id = data.order_id

    this.paymentOptions = data

    this.setPaymentMethods(data.payment_methods)
    this.setSupportedCurrencies(data.supported_currencies, data.settlement_currency)

    if (this.RootStore.configStore.gateway.customerCards) {
      this.setCards(data.cards)
    }

    if (this.RootStore.configStore.transaction_mode === "save_card" || this.RootStore.configStore.transaction_mode === "token") {
      this.supported_currencies = this.setFormSupportedCurrencies(data.supported_currencies)
    }

    var methods_currencies = this.supported_currencies_based_on_methods
    console.log("methods_currencies", methods_currencies)

    if (this.supported_currencies && Array.isArray(this.supported_currencies.slice()) && this.supported_currencies.length > 0) {
      // //console.log('hey', self.supported_currencies.slice().length);

      if (methods_currencies.length <= 1 || !methods_currencies.includes(currency)) {
        self.setCurrentCurrency(self.supported_currencies[0])
        self.current_amount = self.supported_currencies[0].amount
        self.customer_cards_by_currency = self.savedCardsByCurrency
        self.RootStore.configStore.order = self.supported_currencies[0]

        if (self.supported_currencies[0].currency == data.settlement_currency) {
          self.settlement_currency = self.supported_currencies[0]
        }

        self.isLoading = false
        self.RootStore.uIStore.stopLoading()
      } else if (methods_currencies.length > 1) {
        var merchant_currency,
          customer_currency = null

        self.supported_currencies.filter(function (el) {
          if (currency.indexOf(el.currency) >= 0) {
            console.log("v merchant currency is: ", el)
            merchant_currency = el
          }

          if (el.currency == data.settlement_currency) {
            self.settlement_currency = el
          }
        })

        console.log("merchant_currency ===>", merchant_currency)

        self.setCurrentCurrency(merchant_currency)
        self.current_amount = merchant_currency.amount
        self.customer_cards_by_currency = self.savedCardsByCurrency
        self.RootStore.configStore.order = merchant_currency

        self.isLoading = false
        self.RootStore.uIStore.stopLoading()

        withTimeout(
          1000,
          self.RootStore.apiStore.getCurrentCountry().then(async (customer_cur) => {
            self.supported_currencies.filter(function (el) {
              if (customer_cur && customer_cur.code != null && customer_cur.code.indexOf(el.currency) >= 0) {
                console.log("v customer currency is: ", el)
                customer_currency = el
              }
            })

            if (customer_currency != null && customer_currency.currency != merchant_currency.currency) {
              var currency_name = this.RootStore.localizationStore.getContent(
                "supported_currencies_title_" + customer_currency.currency.toLowerCase(),
                null
              )

              this.RootStore.uIStore.setErrorHandler({
                visable: true,
                type: "warning",
                code: "Exchange Currency",
                msg: this.RootStore.localizationStore.getContent("exchange_currency_message", null).replace("%@", currency_name),
                options: [
                  {
                    title: this.RootStore.localizationStore.getContent("alert_cancel_payment_status_undefined_btn_confirm_title", null),
                    action: this.confirmExchangeCurrency.bind(this, customer_currency)
                  },
                  {
                    title: "×",
                    action: this.cancelExchangeCurrency.bind(this)
                  }
                ]
              })
            }
          })
        )
      } else {
        this.isLoading = true
        this.RootStore.uIStore.showMsg("warning", this.RootStore.localizationStore.getContent("gosell_gateway_configration_msg", null), null)
      }
    } else {
      this.isLoading = true
      this.RootStore.uIStore.showMsg("warning", this.RootStore.localizationStore.getContent("gosell_gateway_configration_msg", null), null)
    }
    // } else {
    //   this.isLoading = true;
    //   this.RootStore.uIStore.showMsg(
    //     "warning",
    //     this.RootStore.localizationStore.getContent(
    //       "gosell_something_went_wrong",
    //       null
    //     ),
    //     null
    //   );
    // }
  }

  confirmExchangeCurrency(customer_currency) {
    this.setCurrentCurrency(customer_currency)
    this.RootStore.uIStore.mainHeight = 0
  }

  cancelExchangeCurrency() {
    this.RootStore.uIStore.setErrorHandler({})
  }

  setPaymentMethods(value) {
    var self = this

    this.payment_methods = {}
    this.webPayments = []
    this.cardPayments = []
    this.applePay = []

    this.supported_payment_methods = this.RootStore.configStore.gateway.supportedPaymentMethods

    console.log("supported_payment_methods", this.supported_payment_methods.slice())

    if (typeof this.supported_payment_methods === "object" || Array.isArray(this.supported_payment_methods.slice())) {
      value.filter(function (method) {
        if (self.supported_payment_methods.indexOf(method.name) >= 0) {
          self.filter(method)
        }
      })

      self.RootStore.applePayStore.checkApplePay()
    } else if (this.supported_payment_methods == "all") {
      value.forEach((method, i) => {
        self.filter(method)
      })

      self.RootStore.applePayStore.checkApplePay()
    } else {
      self.isLoading = true
      self.RootStore.uIStore.showMsg("warning", self.RootStore.localizationStore.getContent("gosell_gateway_configration_msg", null), null)
    }
  }

  filter(method) {
    var self = this
    self.payment_methods[method.name] = method
    method.supported_currencies.forEach(function (cur) {
      if (self.supported_currencies_based_on_methods.indexOf(cur) < 0) {
        self.supported_currencies_based_on_methods.push(cur)
      }
    })

    if (method.payment_type == "web") {
      self.webPayments.push(method)
    } else if (method.payment_type == "card") {
      self.cardPayments.push(method)
    } else if (method.payment_type === "apple_pay") {
      self.applePay.push(method)
    }
  }

  computed
  get getWebPaymentsByCurrency() {
    var self = this

    if (Array.isArray(this.webPayments.slice())) {
      var arr = []
      this.webPayments.forEach(function (payment) {
        var curs = payment.supported_currencies
        for (var i = 0; i < curs.length; i++) {
          if (curs[i] === self.current_currency.currency) {
            arr.push(payment)
          }
        }
      })

      return arr
    } else {
      return null
    }
  }

  computed
  get getApplePayByCurrency() {
    var current_currency = this.current_currency.currency
    // console.log("apple pay[0]", this.applePay[0]);

    var currency = this.applePay.length > 0 && this.applePay[0].supported_currencies

    for (var i = 0; i < currency.length; i++) {
      // console.log("+++++++++++++++++++++++++++++++++++++++++ currency[i]", currency[i]);

      if (current_currency == currency[i]) {
        return true
      }
    }
    return false
  }

  computed
  get getCardPaymentsByCurrency() {
    var self = this

    if (Array.isArray(this.cardPayments.slice())) {
      var arr = []
      this.cardPayments.forEach(function (payment) {
        var curs = payment.supported_currencies
        for (var i = 0; i < curs.length; i++) {
          if (curs[i] === self.current_currency.currency) {
            arr.push(payment)
          }
        }
      })

      return arr
    } else {
      return null
    }
  }

  computed
  get savedCardsByCurrency() {
    var self = this

    if (
      this.cardPayments &&
      Array.isArray(this.cardPayments.slice()) &&
      this.cardPayments.length > 0 &&
      this.customer_cards &&
      Array.isArray(this.customer_cards.slice()) &&
      this.customer_cards.length > 0
    ) {
      var arr = []
      this.customer_cards.forEach(function (card) {
        var curs = card.supported_currencies
        // //console.log('card', card.brand);

        for (var i = 0; i < curs.length; i++) {
          if (curs[i] === self.current_currency.currency) {
            if (Array.isArray(self.supported_payment_methods.slice())) {
              for (var j = 0; j < self.supported_payment_methods.slice().length; j++) {
                if (card.brand === self.supported_payment_methods[j]) {
                  arr.push(card)
                }
              }
            } else {
              arr.push(card)
            }
          }
        }
      })

      return arr
    } else {
      return null
    }
  }

  getCardDetails(cardName) {
    if (Array.isArray(this.cardPayments.slice()) && this.cardPayments.length > 0) {
      var self = this
      var selectedCard = null
      this.cardPayments.forEach(function (card) {
        if (cardName === card.name) {
          selectedCard = card
        }
      })
      return selectedCard
    } else {
      return null
    }
  }

  setCurrentCurrency(value) {
    if (this.current_currency.currency != undefined) {
      this.old_currency = this.current_currency.currency
    } else {
      console.log("value.currency", value.currency)
      this.old_currency = value.currency
    }

    this.current_currency = value
    this.customer_cards_by_currency = this.savedCardsByCurrency
    this.active_payment_option_total_amount = value.amount

    if (this.RootStore.formStore.card != null) {
      this.RootStore.formStore.switchCurrency(value)
      // this.RootStore.formStore.clearCardForm();
      this.RootStore.uIStore.setErrorHandler({})
    }

    this.isApplePayAvailable = this.getApplePayByCurrency

    this.RootStore.uIStore.calcElementsHeight("gosell-gateway-payment-options")
  }

  setSupportedCurrencies(value, settlement_currency) {
    var self = this
    this.supported_currencies = {}
    var config_currencies = this.RootStore.configStore.gateway.supportedCurrencies

    self.settlement_currency = value.filter(function (el) {
      return settlement_currency.indexOf(el.currency) >= 0
    })
    // //console.log('ccc', config_currencies);
    if (typeof config_currencies == "object" && Array.isArray(config_currencies.slice())) {
      self.currencies = config_currencies

      self.supported_currencies = value.filter(function (el) {
        return config_currencies.indexOf(el.currency) >= 0
      })
      // self.settlement_currency = settlement_currency.indexOf(el.currency) >= 0;

      console.log("supported_currencies", self.supported_currencies)
      console.log("settlement", self.settlement_currency)
    } else {
      switch (config_currencies) {
        case "all":
          self.currencies = "all"
          self.supported_currencies = value
          break
        case "gcc":
          self.currencies = self.gcc
          self.supported_currencies = value.filter(function (el) {
            return self.gcc.indexOf(el.currency) >= 0
          })
          break
        default:
          self.currencies = value
          self.supported_currencies = value
          break
      }
      // //console.log('cccc', self.currencies);
    }

    var methods_currencies = this.supported_currencies_based_on_methods

    self.supported_currencies = self.supported_currencies.filter(function (el) {
      return methods_currencies.indexOf(el.currency) >= 0
    })
  }

  //supported currencies based on cards list (saveCard & token modes only)
  setFormSupportedCurrencies(value) {
    // //console.log('this.cardPayments', this.cardPayments);
    var self = this

    if (Array.isArray(this.cardPayments.slice())) {
      var self = this
      var arr = []

      self.supported_currencies = value.filter(function (el) {
        self.cardPayments.forEach(function (card) {
          if (card.supported_currencies.indexOf(el.currency) == 0) {
            arr.indexOf(el) === -1 ? arr.push(el) : null
          }
        })
      })

      return arr
    } else {
      return null
    }

    // self.supported_currencies = value.filter(function(el){
    //     return self.cardPayments.indexOf(el.currency) >= 0;
    // });
  }

  // sort() {
  //   this.webPayments = [];
  //   this.cardPayments = [];
  //   this.applePay = [];

  //   if (this.payment_methods && Object.keys(this.payment_methods).length > 0) {
  //     var self = this;

  //     this.payment_methods = this.payment_methods;

  //     var method = null;

  //     for (var i = 0; i < Object.keys(this.payment_methods).length; i++) {
  //       method = this.payment_methods[i];

  //       try {
  //         if (method.payment_type == "web") {
  //           this.webPayments.push(method);
  //         } else if (method.payment_type == "card") {
  //           this.cardPayments.push(method);
  //         } else if (method.payment_type === "apple_pay") {
  //           this.applePay.push(method);
  //         }
  //       } catch (err) {
  //         //console.log('error', err)
  //       }
  //     }
  //   }
  // }

  saveCardOption(value) {
    console.log("card wallet", this.card_wallet)

    if (this.card_wallet) {
      if (this.save_card_active) {
        this.save_card_option = value
        if (document.getElementById("gosell-gateway-form-swticher")) document.getElementById("gosell-gateway-form-swticher").checked = value
      }
    }
  }

  computed
  get getCurrentValue() {
    let old = this.RootStore.configStore.order
    let current = this.RootStore.paymentStore.current_currency

    var title = { main: this.getMainAmount }

    if (current.currency !== old.currency) {
      title = { main: this.getCurrentAmount, secondary: this.getMainAmount }
    }

    return title
  }

  computed
  get getMainAmount() {
    let old = this.RootStore.configStore.order
    let old_amount = this.RootStore.uIStore.formatNumber(old.amount.toFixed(old.decimal_digit))

    var old_symbol = this.RootStore.localizationStore.getContent("supported_currencies_symbol_" + old.currency.toLowerCase(), null)

    if (this.RootStore.uIStore.dir === "rtl") {
      return old_amount + " " + old_symbol
    } else {
      return old_symbol + " " + old_amount
    }
  }

  computed
  get getCurrentAmount() {
    if (this.RootStore.paymentStore.current_currency.amount != null) {
      let current = this.RootStore.paymentStore.current_currency
      let new_amount = this.RootStore.uIStore.formatNumber(current.amount.toFixed(current.decimal_digit))

      var new_symbol = this.RootStore.localizationStore.getContent("supported_currencies_symbol_" + current.currency.toLowerCase(), null)

      if (this.RootStore.uIStore.dir === "rtl") {
        return new_amount + " " + new_symbol
      } else {
        return new_symbol + " " + new_amount
      }
    }
  }
}

mobx.decorate(PaymentStore, {
  order_id: mobx.observable,
  payment_methods: mobx.observable,
  current_currency: mobx.observable,
  current_amount: mobx.observable,
  settlement_currency: mobx.observable,
  supported_currencies: mobx.observable,
  currencies: mobx.observable,
  //customer cards list
  customer_cards: mobx.observable,
  customer_cards_by_currency: mobx.observable,

  active_payment_option: mobx.observable,
  active_payment_option_fees: mobx.observable,
  active_payment_option_total_amount: mobx.observable,
  source_id: mobx.observable,

  webPayments: mobx.observable,
  cardPayments: mobx.observable,
  isLoading: mobx.observable,
  selected_card: mobx.observable,
  card_wallet: mobx.observable,
  save_card_option: mobx.observable,
  three_d_Secure: mobx.observable,
  /*from here*/
  currency: mobx.observable,
  amount: mobx.observable,
  customer: mobx.observable,
  redirectURL: mobx.observable,
  metadata: mobx.observable,
  ref: mobx.observable,
  tranx_desc: mobx.observable,
  receipt: mobx.observable,
  transaction: mobx.observable,
  cardToken: mobx.observable,
  status_display_duration: mobx.observable,
  otp_resend_interval: mobx.observable,
  otp_resend_attempts: mobx.observable,
  authenticate: mobx.observable,
  transaction: mobx.observable,
  old_currency: mobx.observable,
  supported_payment_methods: mobx.observable,
  fawryPay: mobx.observable,
  applePay: mobx.observable,
  isApplePayAvailable: mobx.observable,
  paymentOptions: mobx.observable
})

export default PaymentStore
