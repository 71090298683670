import { decorate, observable } from "mobx";
import axios from "axios";

class MerchantStore {
  constructor(RootStore) {
    this.RootStore = RootStore;

    this.merchant = null;
    this.pk = null;
    this.session = null;
    this.country_code = null;

    this.logo = null;
    this.name = null;
    this.description = null;
    this.contact = null;

    this.isLoading = true;
  }

  computed;
  get getLoadingStatus() {
    return this.isLoading;
  }

  setLoadingStatus(value) {
    this.isLoading = value;
  }

  setDetails(info) {
    console.log("info", info);
    if (info != null) {
      this.merchant = info;

      //console.log('info', info);
      this.logo = info.logo ? info.logo : null;
      this.name = info.name ? info.name : null;
      this.country_code = info.country_code ? info.country_code : null;
      this.description = info.description ? info.description : null;
      this.contact = info.contact ? info.contact : null;

      this.isLoading = false;
    }

    if (
      this.RootStore.configStore.transaction_mode === "token" ||
      this.RootStore.configStore.transaction_mode === "save_card"
    ) {
      this.isLoading = false;
      this.RootStore.paymentStore.isLoading = false;
      this.RootStore.uIStore.stopLoading();
    }
    console.log("inside merchant store", this.isLoading);
  }
}

decorate(MerchantStore, {
  merchant: observable,
  sk: observable,
  pk: observable,
  session: observable,
  logo: observable,
  name: observable,
  description: observable,
  contact: observable,
  isLoading: observable,
  country_code: observable,
});

export default MerchantStore;
