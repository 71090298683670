import React, { Component } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import Paths from "../../webpack/paths";

class BackBtn extends Component {
  state = {
    width: "auto",
    height: "auto",
    img: null,
    back: false,
  };

  componentWillMount() {
    // var store = this.props.store;

    this.setState({
      width: this.props.width,
      height: this.props.height,
      img: this.props.logo,
      back: this.props.back,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      width: nextProps.width,
      height: nextProps.height,
      img: nextProps.logo,
      back: nextProps.back,
    });
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //
  //   //console.log('== back 1', nextProps.back);
  //   //console.log('== back 2', this.props.back);
  //   //  checks for logo changes, and if the next logo is null
  //   if ((nextProps.back  ==  this.props.back) || !nextProps.back ){
  //     return false
  //   } else {
  //     return true
  //   }
  //
  //
  // }

  render() {
    const BackLayer = styled.div`
      width: ${this.props.store.uIStore.modal.headerStyle.iconStyle.width};
      height: ${this.props.store.uIStore.modal.headerStyle.iconStyle.height};
      position: absolute;
      // top: 0;
      // left: ${this.props.store.uIStore.isMobile ? "5px" : "0px"}
      // bottom: 0;
      border-radius: 50%;
      transition: 5s ease-in-out;
      ${this.props.store.uIStore.modal.headerStyle.iconStyle}
      background-color: #4a4847;
    `;

    var img =
      this.props.store.uIStore.dir == "ltr"
        ? Paths.imgsPath + "arrow-new.svg"
        : Paths.imgsPath + "arrowReverse-new.svg";

    const Img = styled.img`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    `;

    var condition =
      ((this.props.store.uIStore.pageIndex != 0 &&
        this.props.store.uIStore.pageIndex != 1 &&
        this.props.store.uIStore.pageIndex != 2) ||
        this.props.store.uIStore.show_order_details) &&
      this.props.store.uIStore.isMobile;
    console.log(
      "iconStyle",
      this.props.store.uIStore.modal.headerStyle.iconStyle,
    );

    return (
      <React.Fragment>
        {this.props.store.uIStore.getPageIndex === 6 ? (
          <React.Fragment>
            <img
              src={Paths.imgsPath + "fawry_icon.svg"}
              width={this.props.store.uIStore.modal.headerStyle.iconStyle.width}
              height={
                this.props.store.uIStore.modal.headerStyle.iconStyle.height
              }
              style={this.props.store.uIStore.modal.headerStyle.iconStyle}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <BackLayer
              style={{ opacity: condition ? "1" : "0" }}
              onClick={(e) =>
                condition ? this.props.store.actionStore.goBack() : null
              }
            >
              <Img
                src={img}
                style={{ opacity: condition ? "1" : "0" }}
                height="35"
              ></Img>
            </BackLayer>
            <img
              src={this.props.store.merchantStore.logo}
              width={this.props.store.uIStore.modal.headerStyle.iconStyle.width}
              height={
                this.props.store.uIStore.modal.headerStyle.iconStyle.height
              }
              style={this.props.store.uIStore.modal.headerStyle.iconStyle}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default observer(BackBtn);
