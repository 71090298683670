import { decorate, observable, computed } from "mobx"
import axios from "axios"

class ActionStore {
  constructor(RootStore) {
    this.RootStore = RootStore

    this.handleOrderDetailsClick = this.handleOrderDetailsClick.bind(this)
    this.currenciesHandleClick = this.currenciesHandleClick.bind(this)
    this.handleBusinessInfoClick = this.handleBusinessInfoClick.bind(this)
    this.handleCustomerCardsClick = this.handleCustomerCardsClick.bind(this)

    this.onWebPaymentClick = this.onWebPaymentClick.bind(this)
    this.onPayBtnClick = this.onPayBtnClick.bind(this)

    this.handleExtraFeesClick = this.handleExtraFeesClick.bind(this)
    this.handleOTPClick = this.handleOTPClick.bind(this)

    this.handleClose = this.handleClose.bind(this)

    this.sliderAnimationDuration = 1000
    this.slideEnded = true

    this.goBack = this.goBack.bind(this)
  }

  waitTillSlideEndes() {
    this.slideEnded = false
    setTimeout(
      function () {
        this.slideEnded = true
      }.bind(this),
      this.sliderAnimationDuration - this.sliderAnimationDuration / 3
    )
  }

  resetSettings() {
    this.RootStore.uIStore.setIsActive(null)
    this.RootStore.paymentStore.selected_card = null
    this.RootStore.paymentStore.active_payment_option = null
    this.RootStore.paymentStore.active_payment_option_fees = 0
    // this.RootStore.paymentStore.active_payment_option = null;
    // this.RootStore.paymentStore.active_payment_option_total_amount = 0;
    // this.RootStore.paymentStore.active_payment_option_fees = 0;

    this.RootStore.uIStore.shakeCards(false)
    this.RootStore.formStore.clearCardForm()
    // this.RootStore.uIStore.goSellBtn({
    //   title: this.RootStore.configStore.btn,
    //   active: false,
    //   loader: false
    // });
  }

  goBack() {
    // if(this.RootStore.uIStore.getPageIndex == 3){
    //   // this.RootStore.paymentStore.setCurrentCurrency(current);
    //   this.RootStore.uIStore.mainHeight = 0;
    // }

    if (this.RootStore.uIStore.isMobile) {
      this.RootStore.uIStore.setPageIndex(0, "x")
    } else {
      this.RootStore.uIStore.setSubPage(-1)
    }

    if (this.RootStore.uIStore.show_order_details) {
      this.handleOrderDetailsClick()
    }
  }

  handleOrderDetailsClick() {
    if (
      (this.RootStore.uIStore.btn.active && this.RootStore.uIStore.btn.loader) ||
      this.RootStore.uIStore.getPageIndex == 1 ||
      this.RootStore.uIStore.getPageIndex == 2
    ) {
      this.RootStore.uIStore.warningHandler()
    } else if (this.RootStore.uIStore.delete_card === null) {
      if (this.slideEnded) {
        this.waitTillSlideEndes()
        this.resetSettings()

        if (this.RootStore.uIStore.isMobile) {
          if (this.RootStore.uIStore.show_order_details) {
            this.RootStore.uIStore.show_order_details = false
            this.RootStore.uIStore.setPageIndex(0, "x")
          } else {
            this.RootStore.uIStore.show_order_details = true
            this.RootStore.uIStore.setPageIndex(5, "x")
          }
        } else {
          if (this.RootStore.uIStore.getPageIndex != 0) {
            this.RootStore.uIStore.setPageIndex(0, "x")
          }

          if (this.RootStore.uIStore.getSubPage === 1 || this.RootStore.uIStore.getSubPage === 0) {
            this.RootStore.uIStore.setSubPage(-1)
          }

          this.RootStore.uIStore.goSellBtn({
            title: this.RootStore.configStore.btn,
            active: false,
            loader: false
          })

          var self = this

          if (this.RootStore.uIStore.getSubPage === 1 || this.RootStore.uIStore.getSubPage === 0) {
            this.RootStore.uIStore.setSubPage(-1)
          }

          this.RootStore.uIStore.goSellBtn({
            title: this.RootStore.configStore.btn,
            active: false,
            loader: false
          })

          this.resetSettings()
          var self = this

          setTimeout(function () {
            var paymentOptions = document.getElementById("gosell-gateway-payment-options")
            var order = document.getElementById("gosell-gateway-order-details")

            if (self.RootStore.uIStore.show_order_details) {
              paymentOptions.style.height = order.style.height
              order.style.height = 0

              self.RootStore.uIStore.show_order_details = false
            } else {
              order.style.height = paymentOptions.style.height
              paymentOptions.style.height = 0

              self.RootStore.uIStore.show_order_details = true
            }
          }, 500)
        }
      }
    }
  }

  handleBusinessInfoClick() {
    // //

    if (this.RootStore.configStore.contactInfo && this.RootStore.merchantStore.contact && this.RootStore.merchantStore.contact.length > 0) {
      if (this.RootStore.uIStore.btn.active && this.RootStore.uIStore.btn.loader) {
        this.RootStore.uIStore.warningHandler()
      } else if (this.RootStore.uIStore.delete_card === null) {
        if (document.getElementById("gosell-gateway-business-info") != null) {
          var sideMenu = document.getElementById("gosell-gateway-side-menu").clientHeight
          var businessInfo = document.getElementById("gosell-gateway-business-info").scrollHeight

          sideMenu < businessInfo
            ? (document.getElementById("gosell-gateway-business-info").style.height = "fit-content")
            : (document.getElementById("gosell-gateway-business-info").style.height = "100%")
        }

        this.resetSettings()

        if (this.RootStore.uIStore.show_order_details) {
          this.handleOrderDetailsClick()
        }

        if (this.RootStore.uIStore.getPageIndex === 4 || this.RootStore.uIStore.getSubPage === 1) {
          this.RootStore.uIStore.setPageIndex(0, "x")
          this.RootStore.uIStore.setSubPage(-1)
        } else {
          // open currencies list
          if (this.RootStore.uIStore.isMobile) {
            this.RootStore.uIStore.setPageIndex(4, "x")
          } else {
            this.RootStore.uIStore.setSubPage(1)
          }
        }
      }
    }
  }

  currenciesHandleClick(e) {
    if (this.RootStore.uIStore.btn.active && this.RootStore.uIStore.btn.loader) {
      this.RootStore.uIStore.warningHandler()
    } else if (this.RootStore.uIStore.delete_card === null) {
      this.resetSettings()

      this.RootStore.uIStore.show_order_details = false

      if (this.RootStore.uIStore.getPageIndex === 3 || this.RootStore.uIStore.getSubPage === 0) {
        this.RootStore.uIStore.setPageIndex(0, "x")
        this.RootStore.uIStore.setSubPage(-1)
      } else {
        this.RootStore.uIStore.setIsActive(null)
        // this.RootStore.paymentStore.selected_card = null;
        // this.RootStore.uIStore.payBtn(false);

        if (this.RootStore.paymentStore.supported_currencies.length > 1) {
          if (this.RootStore.uIStore.isMobile) {
            this.RootStore.uIStore.setPageIndex(3, "x")
          } else {
            this.RootStore.uIStore.setSubPage(0)
          }
        }
      }
    }
  }

  handleCustomerCardsClick(ref, obj) {
    var self = this
    this.RootStore.uIStore.show_order_details = false

    if (this.RootStore.uIStore.btn.active && this.RootStore.uIStore.btn.loader) {
      this.RootStore.uIStore.warningHandler()
    } else {
      if (this.RootStore.paymentStore.selected_card !== ref.id && !this.RootStore.uIStore.shake_cards && this.RootStore.uIStore.delete_card == null) {
        this.RootStore.uIStore.setSubPage(-1)
        // this.RootStore.uIStore.setActivePage(0);
        // this.RootStore.uIStore.isMobile ? this.RootStore.uIStore.setSubPage(0) : this.RootStore.uIStore.setSubPage(-1);

        this.RootStore.uIStore.setIsActive("CARD")
        this.RootStore.paymentStore.selected_card = ref.id

        this.RootStore.formStore.clearCardForm()

        //
        // this.RootStore.paymentStore.setActivePaymentOption(obj.scheme, null);

        var total =
          // self.RootStore.paymentStore.active_payment_option_total_amount > 0
          // ?
          self.RootStore.paymentStore.getCurrentAmount
        // : "";

        this.RootStore.uIStore.goSellBtn({
          title: this.RootStore.configStore.btn + " " + total,
          color: "#2ACE00",
          active: true,
          loader: false
        })

        //
      } else {
        this.resetSettings()
      }
    }
  }

  onPayBtnClick() {
    var self = this

    this.RootStore.uIStore.startBtnLoader()

    if (this.RootStore.uIStore.getIsActive === "CARD") {
      var card_id = this.RootStore.paymentStore.selected_card
      //

      this.RootStore.apiStore.getSavedCardToken(card_id).then((token) => {
        //

        var obj = token.data
        //

        self.RootStore.paymentStore.setActivePaymentOption(obj.card.brand, obj.id).then((res) => {
          if (res) {
            // self.RootStore.paymentStore.source_id = obj.id;

            if (token.status == 200) {
              if (self.RootStore.paymentStore.active_payment_option_fees > 0) {
                self.RootStore.uIStore.setPageIndex(1, "y")
                self.RootStore.uIStore.stopBtnLoader()
              } else {
                this.RootStore.apiStore.handleTransaction(self.RootStore.paymentStore.source_id, "CARD", 0.0).then((charge) => {
                  //
                  self.RootStore.uIStore.stopBtnLoader()
                })
              }
            }
          }
        })
      })
    }
  }

  onWebPaymentClick(payment) {
    var self = this

    if (this.RootStore.uIStore.btn.active && this.RootStore.uIStore.btn.loader) {
      this.RootStore.uIStore.warningHandler()
    } else if (this.RootStore.uIStore.delete_card === null) {
      this.resetSettings()

      if (this.RootStore.uIStore.getSubPage === 1 || this.RootStore.uIStore.getSubPage === 0) {
        this.RootStore.uIStore.setSubPage(-1)
      }

      if (this.RootStore.uIStore.getPageIndex == 0) {
        this.RootStore.formStore.clearCardForm()
        this.RootStore.uIStore.setIsActive("WEB")
        if (payment.extra_fees) {
          self.RootStore.paymentStore.setActivePaymentOption(payment.name, payment.source_id).then((res) => {
            if (res) {
              this.RootStore.uIStore.setPageIndex(1, "y")
            }
          })
        } else {
          self.RootStore.paymentStore.setActivePaymentOption(payment.name, payment.source_id).then((res) => {
            if (res) {
              this.RootStore.uIStore.startLoading("loader", self.RootStore.localizationStore.getContent("please_wait_msg", null), null)

              this.RootStore.apiStore.handleTransaction(payment.source_id, "WEB", 0.0)
            }
          })
        }
      }
    }
  }

  handleExtraFeesClick() {
    var self = this
    var store = this.RootStore

    //
    if (store.uIStore.getIsActive != null && store.uIStore.getIsActive.toUpperCase() !== "CARD") {
      store.uIStore.startLoading("loader", store.localizationStore.getContent("please_wait_msg", null), null)
    }

    store.apiStore
      .handleTransaction(store.paymentStore.source_id, store.uIStore.getIsActive, store.paymentStore.active_payment_option_fees)
      .then((result) => {
        if (store.uIStore.getIsActive != null && store.uIStore.getIsActive.toUpperCase() !== "CARD") {
          store.uIStore.stopBtnLoader()
        }
      })
  }

  handleOTPClick() {
    var self = this
    var store = this.RootStore

    store.uIStore.goSellBtn({
      active: true,
      loader: true
    })

    store.uIStore.goSellOtp({
      updated: false
    })

    store.uIStore.startLoading("loader", store.localizationStore.getContent("please_wait_msg", null), null)

    //
    //

    store.apiStore.authentication(store.paymentStore.authenticate.type, store.uIStore.otp.value).then((result) => {
      store.uIStore.stopBtnLoader()
      store.uIStore.setIsActive(null)
      store.paymentStore.selected_card = null
    })
  }

  getParams() {
    var URLSearchParams = require("@ungap/url-search-params/cjs")

    var urlParams = new URLSearchParams(window.location.search)

    var token = null
    var tap_id = null

    if (urlParams.has("token")) {
      token = urlParams.get("token")
    }

    if (urlParams.has("tap_id")) {
      tap_id = urlParams.get("tap_id")
    }

    return { token: token, tap_id: tap_id }
  }

  handleModalClose() {
    this.RootStore.uIStore.setOpenModal(false)
    this.RootStore.uIStore.isLoading = true
    this.RootStore.uIStore.mainHeight = 0
    this.RootStore.uIStore.getErrorHandler.visable = false
    this.RootStore.uIStore.show_order_details = false
    this.RootStore.uIStore.setSubPage(-1)
    this.RootStore.uIStore.setPageIndex(0, "x")
    this.RootStore.actionStore.resetSettings()
  }

  handleClose() {
    if (!this.RootStore.uIStore.btn.loader) {
      // show session expired, if the user return to the payment page again after close action
      this.RootStore.apiStore.updateToken(this.RootStore.configStore.token).then((result) => {
        // if (result.response && result.response.code === 200) {
        if (this.RootStore.uIStore.modalMode === "page" && window.location == window.parent.location) {
          if (this.RootStore.configStore.home !== null) {
            window.location = this.RootStore.configStore.home
          } else {
            window.location = this.RootStore.configStore.redirect_btn
          }
        } else {
          this.RootStore.uIStore.setOpenModal(false)
          this.RootStore.uIStore.isLoading = true

          this.RootStore.uIStore.mainHeight = 0

          this.RootStore.uIStore.getErrorHandler.visable = false
          this.RootStore.uIStore.show_order_details = false

          this.RootStore.uIStore.setSubPage(-1)
          this.RootStore.uIStore.setPageIndex(0, "x")
          this.RootStore.actionStore.resetSettings()

          var url = window.location != window.parent.location ? document.referrer : document.location.href

          var close = "close"

          this.RootStore.configStore.app.app_client_version == "1.4.0"
            ? (close = "close")
            : (close = { close: this.RootStore.configStore.redirect_btn })

          parent.postMessage(close, url)
        }
      })
    } else {
      this.RootStore.uIStore.setErrorHandler({
        visable: true,
        type: "warning",
        code: "0",
        msg: this.RootStore.localizationStore.getContent("transaction_in_process", null)
      })
    }
  }

  // deleteSession() {
  //   var token = { token: this.getParams() };
  //
  //   this.RootStore.apiStore.deleteToken(token, result => {
  //
  //   });
  // }
}

decorate(ActionStore, {})

export default ActionStore
